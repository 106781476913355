import { mapState } from 'vuex';
export default {
  data() {
    return {
      totalAmount: 0,
      isLogin: false,
      submitted: false,
      payment: false,
      complete: false,
      selectedPayment: 'stripe',
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.product.cart,
      getAmount() {
        return (this.totalAmount = this.$store.getters['product/getTotalAmount']);
      },
    }),
  },
  methods: {
    onSubmit() {
      let roomsHasError = false;
      this.bookDetails.rooms.map((room) => {
        room.guests.map((guest) => {
          if (guest.first_name.length <= 1 || guest.last_name.length <= 1) {
            guest.errormsg = this.$i18n.t('errors.RequiredInput');
            roomsHasError = true;
          } else {
            delete guest.errormsg;
          }
        });
      });

      if (!this.userContact.phone.value) {
        this.userContact.phone.errormsg = this.$i18n.t('errors.RequiredInput');
      } else {
        this.userContact.phone.errormsg = '';
      }

      if (!this.userContact.email.value) {
        this.userContact.email.errormsg = this.$i18n.t('errors.RequiredInput');
      } else if (!this.validEmail(this.userContact.email.value)) {
        this.userContact.email.errormsg = this.$i18n.t('errors.InvalidEmail');
      } else {
        this.userContact.email.errormsg = '';
      }

      return this.userContact.phone.errormsg || this.userContact.email.errormsg || roomsHasError ? true : false;
    },
    validEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
