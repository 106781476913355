import { hotelsService } from '../../_services';

const state = {
  hotels: [],
  filteredHotels: [],
  price: [],
  selectedHotel: {},
  isLoadHotel: false,
  isLoadHotelRooms: false,
};
const getters = {
  hotelsListData: (state) => () => state.hotels,
  price: (state) => () => state.price,
  selectedHotel: (state) => state.selectedHotel,
  isLoadHotel: (state) => state.isLoadHotel,
  isLoadHotelRooms: (state) => state.isLoadHotelRooms,
};
const actions = {
  getHotelDataById({ commit }, paramsHotel) {
    commit('setLoadRoomsHotel', true);
    return hotelsService.getHotelById(paramsHotel).then(
      (res) => {
        commit('getHotelSuccess', res.data.data);
        return res.data;
      },
      (error) => commit('getHotelFailure', error)
    );
  },
  getHotelPageInfo({ commit }, params) {
    return hotelsService.getHotelPage(params).then(
      (res) => {
        setTimeout(() => {
          commit('setLoadRoomsHotel', false);
        }, 300);
        return res.data;
      },
      (error) => {
        console.error(error);
        return error;
      }
    );
  },
  getHotelsByRegionId({ commit }, params) {
    hotelsService.getHotelsByRegionId(params).then(
      (res) => {
        commit('getListHotelsSuccess', res.data.data);
      },
      (error) => commit('getSearchFailure', error)
    );
  },
  priceFilter: (state, payload) => {
    state.filteredHotels = [];
  },
};
const mutations = {
  getHotelSuccess(state, hotel) {
    state.selectedHotel = hotel;
    state.isLoadHotel = false;
  },
  getListHotelsSuccess(state, hotels) {
    state.hotels = hotels;
    state.isLoadHotel = false;
  },
  getHotelFailure(state, error) {
    state.errors = { error };
  },
  priceFilter: (context, payload) => {
    context.commit('priceFilter', payload);
  },
  setLoadHotel(state, isLoad) {
    state.isLoadHotel = isLoad;
  },
  setLoadRoomsHotel(state, isLoad) {
    state.isLoadHotelRooms = isLoad;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
