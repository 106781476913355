<template>
  <div class="col-xl-8">
    <form class="card">
      <div class="card-header">
        <h4 class="card-title mb-0">Edit Profile</h4>
        <div class="card-options">
          <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"
            ><i class="fe fe-chevron-up"></i></a
          ><a class="card-options-remove" href="javascript:void(0)" data-bs-toggle="card-remove"
            ><i class="fe fe-x"></i
          ></a>
        </div>
      </div>
      <div class="card-body">
        <ul class="nav nav-tabs nav-primary" id="pills-warningtab" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              id="pills-warningprofile-tab"
              data-bs-toggle="pill"
              href="#pills-warningprofile"
              role="tab"
              aria-controls="pills-warningprofile"
              aria-selected="false"
              tabindex="-1"
              ><i class="icofont icofont-man-in-glasses"></i>{{ $t('user.profile') }}</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-warningcontact-tab"
              data-bs-toggle="pill"
              href="#pills-warningcontact"
              role="tab"
              aria-controls="pills-warningcontact"
              aria-selected="true"
              ><i class="icofont icofont-company"></i>{{ $t('user.legalEntity') }}</a
            >
          </li>
        </ul>
        <div v-if="userData.legal_entity_details" class="tab-content" id="pills-warningtabContent">
          <div
            class="tab-pane fade active show"
            id="pills-warningprofile"
            role="tabpanel"
            aria-labelledby="pills-warningprofile-tab">
            <div class="row mb-0 m-t-30">
              <div class="col-sm-6 col-md-4">
                <div class="mb-3">
                  <label class="form-label">{{ $t('user.firstName') }}</label>
                  <input
                    class="form-control"
                    v-model="userData.first_name"
                    type="text"
                    :placeholder="$t('user.firstName')" />
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div class="mb-3">
                  <label class="form-label">{{ $t('user.lastName') }}</label>
                  <input
                    class="form-control"
                    v-model="userData.last_name"
                    type="text"
                    :placeholder="$t('user.lastName')" />
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div class="mb-3">
                  <label class="form-label">{{ $t('user.phone') }}</label>
                  <input
                    class="form-control"
                    v-model="userData.phone_number"
                    type="text"
                    :placeholder="$t('user.phone')" />
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div class="mb-3">
                  <label class="form-label">{{ $t('user.position') }}</label>
                  <input
                    class="form-control"
                    v-model="userData.position"
                    type="text"
                    :placeholder="$t('user.position')" />
                </div>
              </div>

              <div class="col-md-12">
                <div>
                  <label class="form-label">{{ $t('user.aboutCompany') }}</label>
                  <textarea
                    class="form-control"
                    v-model="userData.about"
                    rows="5"
                    :placeholder="$t('user.aboutCompany')"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-warningcontact"
            role="tabpanel"
            aria-labelledby="pills-warningcontact-tab">
            <div class="row mb-0 m-t-30">
              <div class="col-sm-6 col-md-4">
                <div class="mb-3">
                  <label class="form-label">{{ $t('user.socialReason') }}</label>
                  <input
                    class="form-control"
                    v-model="userData.legal_entity_details.social_reason"
                    type="text"
                    :placeholder="$t('user.socialReason')" />
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div class="mb-3">
                  <label class="form-label">{{ $t('user.headquarterCity') }}</label>
                  <input
                    class="form-control"
                    v-model="userData.legal_entity_details.city_of_head_office"
                    type="text"
                    :placeholder="$t('user.headquarterCity')" />
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div class="mb-3">
                  <label class="form-label">{{ $t('user.taxpayerId') }}</label>
                  <input
                    class="form-control"
                    v-model="userData.legal_entity_details.taxpayer_id"
                    type="text"
                    :placeholder="$t('user.taxpayerId')" />
                </div>
              </div>

              <div class="col-sm-6 col-md-4">
                <div class="mb-3">
                  <label class="form-label">{{ $t('user.legalEntityCity') }}</label>
                  <input
                    class="form-control"
                    v-model="userData.legal_entity_details.city_of_legal_entity"
                    type="text"
                    :placeholder="$t('user.legalEntityCity')" />
                </div>
              </div>
              <div class="col-sm-8">
                <div class="mb-3">
                  <label class="form-label">{{ $t('user.legalAddress') }}</label>
                  <input
                    class="form-control"
                    v-model="userData.legal_entity_details.legal_address"
                    type="text"
                    :placeholder="$t('user.legalAddress')" />
                </div>
              </div>
              <div class="col-sm-4">
                <div class="mb-3">
                  <label class="form-label">{{ $t('user.postalCode') }}</label>
                  <input
                    class="form-control"
                    v-model="userData.legal_entity_details.zip_code"
                    type="text"
                    :placeholder="$t('user.postalCode')" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-end">
        <button :disabled="isPending" class="btn btn-primary" @click="updateUser" type="button">
          {{ $t('button.updateProfile') }}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      isPending: false,
    };
  },
  props: ['userData'],
  methods: {
    ...mapActions(['updateUserProfile']),
    updateUser() {
      this.isPending = true;
      this.updateUserProfile(this.userData).then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.$toast.add({
            severity: 'success',
            summary: 'Update Success',
            life: 4000,
          });
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Error Server',
            life: 4000,
          });
        }
        this.isPending = false;
      });
    },
  },
};
</script>
