import { API } from '../modules/axios';
const END_POINT = '/b2b/hotels';

const getHotelById = (hotelId) => API.post(END_POINT + '/info', hotelId);
const getHotelsByIds = (hotelsIds) => API.post(END_POINT + '/search/ids', hotelsIds);
const getHotelPage = (params) => API.post(END_POINT + '/search/hp', params);
const getHotelsByRegionId = (params) => API.post(END_POINT + '/info/dump', params);

export const hotelsService = {
  getHotelById,
  getHotelsByIds,
  getHotelPage,
  getHotelsByRegionId,
};
