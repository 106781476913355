<template>
  <div class="login-logo">
    <a class="logo text-center">
      <img
        class="img-fluid for-light"
        src="../../assets/images/logo/logo-trust-luxury-white.svg"
        width="250"
        alt="Logo Trust Luxury" />
      <img
        class="img-fluid for-dark"
        src="../../assets/images/logo/logo-trust-luxury-white.svg"
        alt="Logo Trust Luxury"
    /></a>
  </div>
</template>
