import { usersService } from '../../_services';

const state = {
  listUsers: [],
  isLoadUsers: false,
};
// getters
const getters = {
  allUsers: (state) => state.listUsers,
  isLoadUsers: (state) => state.isLoadUsers,
  errorsUsers: (state) => state.errors,
};
const actions = {
  async getAllUsers({ commit }) {
    commit('isLoadStatus', true);
    await usersService.getAllUsers().then(
      (res) => commit('getAllSuccess', res.data.data),
      (error) => commit('getAllFailure', error)
    );
  },
  getUser({ commit }, idUser) {
    return usersService.getUserById(idUser).then(
      (res) => {
        return res.data;
      },
      (error) => commit('getAllFailure', error)
    );
  },
  async addUser({ commit }, payload) {
    return await usersService.addUser(payload).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          commit('addCandidateSuccess', res.data);
        }
        return res.data;
      },
      (error) => {
        return error;
      }
    );
  },
  async deleteUser({ commit }, payload) {
    return await usersService.deleteUser(payload).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          commit('removeDeletedUser', payload);
        }
        return res;
      },
      (error) => {
        return error;
      }
    );
  },
  updateUserProfile({}, payload) {
    return usersService.updateUser(payload.id, payload).then(
      (res) => {
        return res;
      },
      (error) => {
        return error;
      }
    );
  },
  updateStatus({ commit }, payload) {
    const params = {
      status: payload.status,
      sent_email: payload.sentEmail,
    };
    return usersService.updateStatusUser(payload.idUser, params).then(
      (res) => {
        let userUpdated = {
          idUser: payload.idUser,
          newStatus: params.status,
        };
        commit('updateUserStatus', userUpdated);
        return res;
      },
      (error) => {
        return error;
      }
    );
  },
  getProfile({ commit }) {
    return usersService.getProfile().then(
      (res) => {
        return res.data;
      },
      (error) => commit('getAllFailure', error)
    );
  },
  updateProfile({}, payload) {
    return usersService.updateProfile(payload).then(
      (res) => {
        return res;
      },
      (error) => {
        return error;
      }
    );
  },
};
const mutations = {
  addUserSuccess(state, user) {
    state.listUsers.push(user);
  },
  getAllSuccess(state, users) {
    state.listUsers = users;
    state.isLoadUsers = false;
  },
  getAllFailure(state, error) {
    state.errorsUsers = { error };
  },
  removeDeletedUser(state, idUser) {
    const objWithIdIndex = state.listUsers.findIndex((obj) => obj.id === idUser);
    if (objWithIdIndex > -1) {
      state.listUsers.splice(objWithIdIndex, 1);
    }
  },
  addCandidateSuccess(state, user) {
    state.listUsers.push(user);
  },
  updateUserStatus(state, userUpdated) {
    const objWithIdIndex = state.listUsers.findIndex((obj) => obj.id === userUpdated.idUser);
    if (objWithIdIndex > -1) {
      state.listUsers[objWithIdIndex].status = userUpdated.newStatus;
    }
  },
  isLoadStatus(state, isLoad) {
    state.isLoadUsers = isLoad;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
