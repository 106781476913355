<template>
  <div class="card">
    <div class="card-header">
      <h4>{{ $t('orders.informations') }}</h4>
    </div>
    <div class="card-body">
      <div class="room-box mb-3" v-for="(room, indexRoom) in bookDataGuests" :key="indexRoom">
        <h5>
          {{ roomName }} {{ $t('wording.for') }} {{ room.adults }} {{ $t('wording.adults') }}
          <span v-if="room.children.length">
            {{ $t('wording.and') }} {{ room.children.length }} {{ $t('wording.childrens') }}</span
          >
        </h5>
        <h6 class="mt-4">{{ $t('wording.adults') }}</h6>
        <hr />
        <div v-for="(roomDetails, indexRoomDetails) in bookDetailsRooms[indexRoom].guests" :key="indexRoomDetails">
          <div v-if="!roomDetails.is_child" class="row">
            <div class="mb-3 col-sm-6">
              <label :for="`firstName-${indexRoomDetails}`" class="required-field">{{ $t('user.firstName') }}</label>
              <input
                class="form-control"
                :class="{ 'is-invalid': roomDetails.errormsg }"
                :id="`firstName-${indexRoomDetails}`"
                v-model="roomDetails.first_name" />
              <span class="validate-error txt-danger" v-if="roomDetails.errormsg">{{ roomDetails.errormsg }}</span>
            </div>
            <div class="mb-3 col-sm-6">
              <label :for="`lastName-${indexRoomDetails}`" class="required-field">{{ $t('user.lastName') }}</label>
              <input
                class="form-control"
                :id="`lastName-${indexRoomDetails}`"
                :class="{ 'is-invalid': roomDetails.errormsg }"
                v-model="roomDetails.last_name"
                type="text" />
              <span class="validate-error txt-danger" v-if="roomDetails.errormsg">{{ roomDetails.errormsg }}</span>
            </div>
          </div>
        </div>
        <div v-if="hasChildren(bookDetailsRooms[indexRoom].guests)">
          <h6 class="mt-4">{{ $t('wording.childrens') }}</h6>
          <hr />
          <div v-for="(roomDetails, indexRoomDetails) in bookDetailsRooms[indexRoom].guests" :key="indexRoomDetails">
            <div v-if="roomDetails.is_child" class="row">
              <div class="mb-3 col-sm-5">
                <label :for="`firstName-${indexRoomDetails}`" class="required-field">{{ $t('user.firstName') }}</label>
                <input
                  class="form-control"
                  :class="{ 'is-invalid': roomDetails.errormsg }"
                  :id="`firstName-${indexRoomDetails}`"
                  v-model="roomDetails.first_name" />
                <span class="validate-error txt-danger" v-if="roomDetails.errormsg">{{ roomDetails.errormsg }}</span>
              </div>
              <div class="mb-3 col-sm-5">
                <label :for="`lastName-${indexRoomDetails}`" class="required-field">{{ $t('user.lastName') }}</label>
                <input
                  class="form-control"
                  :id="`lastName-${indexRoomDetails}`"
                  :class="{ 'is-invalid': roomDetails.errormsg }"
                  v-model="roomDetails.last_name"
                  type="text" />
                <span class="validate-error txt-danger" v-if="roomDetails.errormsg">{{ roomDetails.errormsg }}</span>
              </div>
              <div class="mb-3 col-sm-2">
                <label :for="`age-${indexRoomDetails}`" class="required-field">{{ $t('user.age') }}</label>
                <select
                  v-model="roomDetails.age"
                  class="form-control select-filter"
                  disabled
                  :class="{ 'is-invalid': roomDetails.errormsg }">
                  <option v-for="(age, indexAge) of 17" :key="indexAge" :value="age">{{ age }}</option>
                </select>
                <span class="validate-error txt-danger" v-if="roomDetails.errormsg">{{ roomDetails.errormsg }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import checkoutPage from '@/mixins/checkoutPage';

export default {
  mixins: [checkoutPage],
  props: ['bookDataGuests', 'bookDetailsRooms', 'roomName'],
  methods: {
    hasChildren(guests) {
      let children = guests.find((res) => res.is_child);
      return children;
    },
  },
};
</script>
