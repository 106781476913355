<template>
  <div class="card">
    <div class="card-header">
      <h4>{{ $t('orders.contactDetails') }}</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="mb-3 col-sm-6">
          <label for="firstName" class="required-field">{{ $t('user.firstName') }}</label>
          <input
            class="form-control"
            :class="{ 'is-invalid': userContact.first_name.errormsg }"
            id="firstName"
            v-model="userContact.first_name.value"
            type="text" />
          <span
            class="validate-error txt-danger"
            v-if="!userContact.first_name.value || !validEmail(userContact.first_name.value)"
            >{{ userContact.first_name.errormsg }}</span
          >
        </div>
        <div class="mb-3 col-sm-6">
          <label for="lastName" class="required-field">{{ $t('user.lastName') }}</label>
          <input
            class="form-control"
            :class="{ 'is-invalid': userContact.last_name.errormsg }"
            id="lastName"
            v-model="userContact.last_name.value"
            type="text" />
          <span v-if="userContact.last_name.errormsg" class="validate-error txt-danger">{{
            userContact.last_name.errormsg
          }}</span>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-sm-6">
          <label for="inputPassword7" class="required-field">{{ $t('user.email') }}</label>
          <input
            class="form-control"
            :class="{ 'is-invalid': userContact.email.errormsg }"
            id="inputPassword7"
            v-model="userContact.email.value"
            type="email" />
          <span
            class="validate-error txt-danger"
            v-if="!userContact.email.value || !validEmail(userContact.email.value)"
            >{{ userContact.email.errormsg }}</span
          >
        </div>
        <div class="mb-3 col-sm-6">
          <label for="phonenumber" class="required-field">{{ $t('user.phone') }}</label>
          <input
            class="form-control"
            :class="{ 'is-invalid': userContact.phone.errormsg }"
            id="phonenumber"
            v-model="userContact.phone.value"
            type="tel" />
          <span v-if="userContact.phone.errormsg" class="validate-error txt-danger">{{
            userContact.phone.errormsg
          }}</span>
        </div>
      </div>
      <div class="mb-3">
        <label for="inputAddress5">{{ $t('user.address') }}</label>
        <input class="form-control" id="inputAddress5" v-model="userContact.address.value" type="text" />
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="mb-3">
            <label for="inputState">{{ $t('user.country') }}</label>
            <input class="form-control" id="inputState" v-model="userContact.country.value" type="text" />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="mb-3">
            <label for="inputCity">{{ $t('user.city') }}</label>
            <input class="form-control" id="inputCity" v-model="userContact.city.value" type="text" />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="mb-3">
            <label for="inputAddress6">{{ $t('user.postalCode') }}</label>
            <input class="form-control" id="inputAddress6" v-model="userContact.pincode.value" type="number" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import checkoutPage from '@/mixins/checkoutPage';
export default {
  mixins: [checkoutPage],
  props: ['userContact'],
};
</script>

<style></style>
