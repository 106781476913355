<template>
  <li v-if="user" class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img
        class="b-r-10"
        :src="user.user.avatar ? baseURL + user.user.avatar : getImageUrl('user/user.png')"
        width="35"
        alt="profile" />
      <div class="media-body">
        <span>{{ user.user.name }}</span>
        <p v-if="user.user?.roles" class="mb-0 font-roboto">
          {{ user.user?.roles[0]?.name }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <router-link :to="{ name: 'profile' }">
          <vue-feather type="user"></vue-feather><span>{{ $t('menu.MyProfile') }}</span></router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'BookingList' }">
          <vue-feather type="file-text"></vue-feather><span>{{ $t('menu.BookingList') }}</span>
        </router-link>
      </li>
      <li v-if="isAdmin">
        <router-link :to="{ name: 'usersList' }">
          <vue-feather type="users"></vue-feather><span>{{ $t('menu.UsersList') }}</span>
        </router-link>
      </li>
      <li v-if="isAdmin">
        <router-link :to="{ name: 'usersList' }">
          <vue-feather type="settings"></vue-feather><span>{{ $t('menu.Settings') }}</span>
        </router-link>
      </li>
      <li @click="logoutUser">
        <a href="javascript:void(0)">
          <vue-feather type="log-in"></vue-feather><span>{{ $t('menu.Logout') }}</span>
        </a>
      </li>
    </ul>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { baseURL } from '@/constants/config';
import getImage from '@/mixins/getImage';

export default {
  name: 'Profile',
  mixins: [getImage],
  data() {
    return {
      baseURL: baseURL,
    };
  },
  computed: {
    ...mapGetters(['user', 'isAdmin']),
  },
  methods: {
    ...mapActions(['logoutUser']),
  },
};
</script>
