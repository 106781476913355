import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import PrimeVue from 'primevue/config';
import Lightbox from 'vue-easy-lightbox';

import AutoComplete from 'primevue/autocomplete';
import MultiSelect from 'primevue/multiselect';
import SpeedDial from 'primevue/speeddial';
import Tooltip from 'primevue/tooltip';
import Toast from 'primevue/toast';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import InputText from 'primevue/inputtext';

import 'primeicons/primeicons.css';

import 'bootstrap/dist/js/bootstrap.bundle';
import '@/assets/scss/app.scss';

import Breadcrumbs from './components/bread_crumbs';
import VueFeather from 'vue-feather';
import Toaster from '@meforma/vue-toaster';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import en from './locales/en.json';
import fr from './locales/fr.json';
import { defaultLocale, localeOptions } from './constants/config';

const messages = { en, fr };
const locale =
  localStorage.getItem('currentLanguage') &&
  localeOptions.filter((x) => x.id === localStorage.getItem('currentLanguage')).length > 0
    ? localStorage.getItem('currentLanguage')
    : defaultLocale;

const datetimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    },
    longTime: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  fr: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: '2-digit',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    },
    longTime: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};
const i18n = createI18n({
  globalInjection: true,
  locale,
  messages,
  datetimeFormats,
});

createApp(App)
  .use(router)
  .use(i18n)
  .use(store)
  .use(Toaster)
  .use(PrimeVue)
  .use(Lightbox)
  .use(ConfirmationService)
  .use(ToastService)
  .directive('tooltip', Tooltip)
  .component('Toast', Toast)
  .component('AutoComplete', AutoComplete)
  .component('InputText', InputText)
  .component('MultiSelect', MultiSelect)
  .component('SpeedDial', SpeedDial)
  .component('v-select', vSelect)
  .component(VueFeather.name, VueFeather)
  .component('Breadcrumbs', Breadcrumbs)
  .mount('#app');
