<template>
  <div class="container-fluid fixed-middle">
    <div class="row">
      <div class="col">
        <div class="card py-2 px-2 px-sm-5 mw-720">
          <div class="card-body">
            <div v-if="isLoading" class="py-3 px-5">
              <div class="order">
                <div class="order__title text-center">
                  <h4>{{ $t('booking.ProcessingReservation') }}</h4>
                  <p>{{ $t('booking.PleaseWait') }}</p>
                </div>
              </div>
              <div class="loader-box mt-5">
                <div class="loader-17"></div>
              </div>
            </div>
            <div v-else class="order">
              <div class="order__title text-center">
                <h2>{{ $t('booking.BookingSuccess') }}</h2>
                <p>
                  {{ $t('booking.BookingSuccessTitle') }}
                  <strong> {{ $t('booking.orderNumber') }} {{ bookData.num_reservation }} </strong>
                  <strong v-if="wireTransfer" class="my-0 d-block"> {{ $t('booking.BookingSuccessSubject') }} </strong>
                </p>
              </div>
              <div class="order__description">
                <ul v-if="hotel_data">
                  <li>
                    Hôtel : {{ hotel_data?.name }}
                    <span v-if="hotel_data?.star_rating" class="rating">
                      <i v-for="(item, index) in hotel_data?.star_rating" :key="index" class="fa fa-star"></i>
                    </span>
                  </li>
                  <li>Rooms : {{ bookData.order_details.room_data?.name }}</li>
                  <li>CheckIn : {{ $d(bookData.checkin, 'long') }} {{ hotel_data.check_in_time }}</li>
                  <li>CheckOut : {{ $d(bookData.checkout, 'long') }} {{ hotel_data.check_out_time }}</li>
                </ul>
              </div>
              <div class="order__info text-center">
                <p>{{ $t('booking.BookingSuccessMessage') }}</p>
              </div>
              <div class="order__action d-flex justify-content-center gap-3">
                <button v-if="!wireTransfer" class="btn btn-primary" @click="downloadVoucher">
                  {{ $t('button.downloadVoucher') }}
                </button>
                <router-link to="/" class="btn btn-secondary">
                  {{ $t('button.goToHome') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from '@/constants/config';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      isLoading: false,
      bookData: '',
      numberOfNights: '',
      bookingData: '',
      wireTransfer: '',
    };
  },
  methods: {
    ...mapActions({
      getOrderTmpByHash: 'orders/getOrderTmpByHash',
      storeBooking: 'booking/createBooking',
    }),
    downloadVoucher() {
      const link = document.createElement('a');
      link.href = baseURL + this.bookData.voucher;
      link.target = '_blank';
      link.download = this.bookData.voucher.split('/').pop();

      // Simulate a click on the element <a>
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  created() {
    this.bookHash = this.$route.query.book_hash;
    this.wireTransfer = this.$route.query.wire_transfer;
    if (this.bookHash) {
      let params = { book_hash: this.bookHash };
      if (this.wireTransfer) {
        params.wire_transfer = true;
      }
      this.isLoading = true;
      this.storeBooking(params).then((order) => {
        if (order && order.status !== 'error') {
          this.bookData = order.data;
          this.hotel_data = order.data.order_details.hotel_data;
          const date1 = new Date(order.data.checkin);
          const date2 = new Date(order.data.checkout);
          const timeDiff = Math.abs(date2.getTime() - date1.getTime());
          this.numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
          if (order.error && order.error == 'failed_send_email') {
            this.$toast.add({
              severity: 'error',
              summary: this.$t('errors.failed_send_email'),
              life: 4000,
            });
          }
        } else {
          let queryParams = { book_hash: this.bookHash };
          if (order && order.error) {
            queryParams.error = order.error;
          }
          this.$router.push({ name: 'BookingError', query: queryParams });
        }
        this.isLoading = false;
      });
    }
  },
};
</script>

<style lang="scss">
.fixed-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  .order {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
}
.mw-720 {
  max-width: 720px;
}
</style>
