<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="product-page-details">
          <h5 class="my-0">{{ $t('policies.policiesTitle') }}</h5>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div v-if="Object.keys(metapolicyStruct).length > 0" class="col-12 mb-3">
            <h5 class="mb-3">{{ $t('policies.metapolicyStruct') }}</h5>
            <div class="row">
              <div v-for="(policy, index) in metapolicyStruct" :key="index" class="col-lg-3 col-sm-6 mb-3">
                <div v-if="policy.length">
                  <span :class="`size-20 me-2 icon-${index}-solid`"></span>
                  <strong class="mb-2">{{ $t(`policies.${index}.title`) }}</strong>
                  <ul class="ps-3">
                    <li v-for="(policyDetails, indexPolicy) in policy" :key="indexPolicy">
                      <span v-if="policyDetails.age_end && index == 'children'" class="d-block">
                        - <strong> {{ $t(`policies.accommodation_children`) }} :</strong>
                        {{ $t(`policies.${index}.age_end`, { age_end: policyDetails.age_end }) }}
                      </span>
                      <span v-if="policyDetails.territory_type" class="d-block">
                        - <strong> {{ $t(`policies.territory_type`) }} :</strong>
                        {{ $t(`policies.${index}.${policyDetails.territory_type}`) }}
                      </span>
                      <span v-if="policyDetails.meal_type" class="d-block">
                        - <strong> {{ $t(`policies.meal_type`) }} :</strong>
                        {{ $t(`meals.${policyDetails.meal_type}`) }}
                      </span>
                      <span v-if="policyDetails.pets_type" class="d-block">
                        - <strong> {{ $t(`policies.price_unit`) }} :</strong>
                        {{ $t(`policies.${index}.${policyDetails.pets_type}`) }}
                      </span>
                      <span v-if="policyDetails.deposit_type" class="d-block">
                        - <strong> {{ $t(`policies.deposit_type`) }} :</strong>
                        {{ $t(`policies.${index}.${policyDetails.deposit_type}`) }}
                      </span>
                      <span v-if="policyDetails.destination_type" class="d-block">
                        - <strong> {{ $t(`policies.destination_type`) }} :</strong>
                        {{ $t(`policies.${index}.${policyDetails.destination_type}`) }}
                      </span>
                      <span v-if="policyDetails.check_in_check_out_type" class="d-block">
                        - <strong>{{ $t(`policies.price_unit`) }} :</strong>
                        {{ $t(`policies.${index}.${policyDetails.check_in_check_out_type}`) }}</span
                      >
                      <span v-if="policyDetails.fee_type" class="d-block">
                        - <strong>{{ $t(`policies.service`) }} :</strong>
                        {{ $t(`policies.${index}.${policyDetails.fee_type}`) }}</span
                      >
                      <span v-if="policyDetails.price !== '0.00' && index == 'children_meal'" class="d-block">
                        - <strong>{{ $t(`policies.price`) }} :</strong>
                        {{ policyDetails.price + ' ' + policyDetails.currency }}
                        <span v-if="policyDetails.age_end">{{
                          $t(`policies.${index}.age_end`, { age_end: policyDetails.age_end })
                        }}</span></span
                      >
                      <span v-else-if="policyDetails.price !== '0.00'" class="d-block">
                        - <strong>{{ $t(`policies.price`) }} :</strong>
                        {{ policyDetails.price + ' ' + policyDetails.currency }}
                        <span v-if="policyDetails.price_unit">{{
                          $t(`policies.${index}.${policyDetails.price_unit}`)
                        }}</span>
                      </span>
                      <span v-else>
                        - <strong>{{ $t(`policies.price`) }} :</strong>
                        {{ $t(`policies.none`) }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div v-if="policiesData.metapolicy_extra_info" class="col-12 mb-3">
            <h5 class="mb-3">{{ $t('policies.metapolicyExtraInfo') }}</h5>
            <div v-html="policiesData.metapolicy_extra_info"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['policiesData'],
  name: 'HotelPoliciesCard',
  computed: {
    metapolicyStruct() {
      if (this.policiesData.metapolicy_struct) {
        return Object.entries(this.policiesData.metapolicy_struct)
          .filter(([key, value]) => value.length > 0)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
      }
    },
  },
};
</script>
