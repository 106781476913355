import { ordersService } from '@/_services/orders.service';

const state = {
  selectedHotel: {},
  selectedRoomHotel: {},
};
const getters = {
  selectedHotel: (state) => state.selectedHotel,
  selectedRoomHotel: (state) => state.selectedRoomHotel,
};
const actions = {
  orderBookingFinish({ commit }, params) {
    return ordersService.orderBookingFinish(params).then(
      (res) => {
        return res.data;
      },
      (error) => console.error(error)
    );
  },
  orderFormPost({ commit }, params) {
    return ordersService.orderForm(params).then(
      (res) => {
        return res.data.data;
      },
      (error) => console.error(error)
    );
  },
  createCheckoutSession({ commit }, params) {
    return ordersService.createCheckoutSession(params).then(
      (res) => {
        return res.data;
      },
      (error) => console.error(error)
    );
  },
  storeOrderTmpByHash({ commit }, params) {
    return ordersService.storeOrderTmpByHash(params).then(
      (res) => {
        return res;
      },
      (error) => console.error(error)
    );
  },
  getOrderTmpByHash({ commit }, bookHash) {
    return ordersService.getOrderTmpByHash(bookHash).then(
      (res) => {
        return res.data.order_information;
      },
      (error) => console.error(error)
    );
  },
  prebookBooking({ commit }, params) {
    return ordersService.prebookBooking(params).then(
      (res) => {
        return res.data;
      },
      (error) => console.error(error)
    );
  },
};
const mutations = {
  setHotel(state, hotel) {
    state.selectedHotel = hotel;
  },
  setRoomHotel(state, room) {
    state.selectedRoomHotel = room;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
