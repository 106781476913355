import { createStore } from 'vuex';

// import 'es6-promise/auto';
import layout from './modules/layout';
import menu from './modules/menu';
import authentication from './modules/authentication';
import users from './modules/users';
import search from './modules/search';
import hotels from './modules/hotels';
import orders from './modules/orders';
import booking from './modules/booking';

export default createStore({
  state: { langIcon: '', langLangauge: '', isActive: false },
  getters: {
    langIcon: (state) => {
      return state.langIcon;
    },
    langLangauge: (state) => {
      return state.langLangauge;
    },
  },
  mutations: {
    LoadSelectedLang(state) {
      const id = localStorage.getItem('currentLanguage');
      const icon = localStorage.getItem('currentLanguageIcon');
      state.langIcon = icon || 'flag-icon-us';
      state.langLangauge = id || 'EN';
    },
    changeLang(state, payload) {
      localStorage.setItem('currentLanguage', payload.id);
      localStorage.setItem('currentLanguageIcon', payload.icon);
      state.langIcon = payload.icon || 'flag-icon-us';
      state.langLangauge = payload.id || 'EN';
      window.location.reload();
    },
    change(state) {
      state.isActive = !state.isActive;
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit('changeLang', payload);
    },
    setLangOnLoad({ commit }, payload) {
      commit('LoadSelectedLang', payload);
    },
  },
  modules: {
    layout,
    menu,
    authentication,
    users,
    search,
    hotels,
    orders,
    booking,
  },
});
