<template>
  <div class="header-wrapper row m-0">
    <div class="col-6 p-0"><Logo /></div>
    <div class="nav-right col-6 pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">
        <li class="language-nav">
          <Language />
        </li>
        <Profile />
      </ul>
    </div>
  </div>
</template>
<script>
import Profile from './profile';
import Logo from './logo.vue';
import Language from './language';

export default {
  components: {
    Profile,
    Logo,
    Language,
  },
};
</script>
