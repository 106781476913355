<template>
  <Breadcrumbs :main="hotel_data?.name" :stars="hotel_data?.star_rating" title="Détails Hotel" />
  <div class="container-fluid">
    <div v-if="hotel_data" class="hotel-page">
      <div class="row product-page-main p-0">
        <HotelSwiper :hotel_data="hotel_data" />
        <!-- <ProductDetailsCard /> -->
        <RightSideCard :hotel_data="hotel_data" />
      </div>
      <HotelExtraDetails :hotel_data="hotel_data" />
      <HotelRoomsList
        v-if="!isLoadHotelRooms && hotel_data.room_groups"
        :roomsData="hotel_data.room_groups"
        :arrangementsRoom="arrangementsRoom"
        :hotelBaseData="basehotel_data"
        :ordersData="ordersData" />
      <Loader v-else />
      <HotelEquipmentCard :equipementData="hotel_data.amenity_groups" />
      <HotelPoliciesCard
        v-if="metapolicy.metapolicy_extra_info?.length > 0 || metapolicy.metapolicy_struct?.length > 0"
        :policiesData="metapolicy" />
    </div>
    <div v-else class="row">
      <div class="col text-center">
        <div class="card">
          <div class="card-body">
            <h3>Data hôtel not found</h3>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HotelSwiper from './HotelSwiper.vue';
import ProductDetailsCard from './ProductDetailsCard.vue';
import RightSideCard from './RightSideCard.vue';
import HotelExtraDetails from './HotelExtraDetails.vue';
import HotelEquipmentCard from './HotelEquipmentCard.vue';
import HotelPoliciesCard from './HotelPoliciesCard.vue';
import HotelRoomsList from './HotelRoomsList.vue';
import Loader from '@/components/Loader.vue';
import Toast from 'primevue/toast';

export default {
  name: 'hotelPage',
  data() {
    return {
      hotel_data: {},
      arrangementsRoom: {},
      basehotel_data: {},
      metapolicy: '',
      ordersData: {},
      countAdults: 0,
      countChildrens: 0,
    };
  },
  components: {
    HotelSwiper,
    ProductDetailsCard,
    RightSideCard,
    HotelExtraDetails,
    HotelEquipmentCard,
    HotelPoliciesCard,
    HotelRoomsList,
    Loader,
    Toast,
  },
  computed: {
    ...mapGetters({ searchData: 'searchData', isLoadHotelRooms: 'hotels/isLoadHotelRooms' }),
  },
  methods: {
    ...mapActions({
      getHotelDataById: 'hotels/getHotelDataById',
      getHotelPageInfo: 'hotels/getHotelPageInfo',
    }),
  },
  created() {
    const hotelId = this.$route.params.hotelId;
    let query = this.$route.query;
    const params = {
      ...query,
      guests: query.guests ? JSON.parse(query.guests) : '',
      id: hotelId,
    };
    const date1 = new Date(query.checkin);
    const date2 = new Date(query.checkout);
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const nightsCount = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const roomsCount = params.guests.length;

    params.guests.map((res) => {
      this.countAdults += res.adults;
      this.countChildrens += res.children;
    });

    this.ordersData = { roomsCount, countAdults: this.countAdults, countChildrens: this.countChildrens, nightsCount };
    let paramsHotel = {
      id: hotelId,
      language: this.$i18n.locale,
    };
    this.getHotelDataById(paramsHotel).then((res) => {
      if (res?.data) {
        this.hotel_data = res.data;
        if (this.hotel_data) {
          this.basehotel_data = {
            id: this.hotel_data?.id,
            name: this.hotel_data?.name,
            star_rating: this.hotel_data?.star_rating,
            check_in_time: this.hotel_data?.check_in_time,
            check_out_time: this.hotel_data?.check_out_time,
          };
          this.metapolicy = {
            metapolicy_extra_info: this.hotel_data.metapolicy_extra_info,
            metapolicy_struct: this.hotel_data.metapolicy_struct,
          };
        } else {
          this.$toast.add({
            severity: 'error',
            summary: this.$i18n.t('errors.AllRequired'),
            life: 4000,
          });
        }
      } else {
        this.$router.back();
      }
    });
    this.getHotelPageInfo(params).then((rangHotel) => {
      this.arrangementsRoom = rangHotel.data.hotels[0];
      /* if (this.hotel_data && this.arrangementsRoom) {
        this.hotel_data.room_groups.map((room, index) => {
          let arrangements = this.arrangementsRoom.rates.find((item) => _.isEqual(item.rg_ext, room.rg_ext));
          this.hotel_data.room_groups[index].arrangements = arrangements || {};
        });
      } */
    });
  },
};
</script>
