<template>
  <div class="card">
    <div class="row product-page-main">
      <div class="col-sm-12">
        <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
          <li class="nav-item" v-for="(item, index) in hotel_data.description_struct" :key="index">
            <a
              class="nav-link"
              :class="{ active: selectedTab == index }"
              :id="`top-${index}-tab`"
              data-bs-toggle="tab"
              :href="`#top-${index}`"
              role="tab"
              :aria-controls="`top-${index}`"
              aria-selected="false"
              @click="selectedTab = index"
              >{{ item.title }}</a
            >
            <div class="material-border"></div>
          </li>
        </ul>
        <div class="tab-content pt-4" id="top-tabContent">
          <div
            class="tab-pane fade"
            role="tabpanel"
            v-for="(description, index) in hotel_data.description_struct"
            :key="index"
            :id="`top-${index}`"
            :class="{ 'active show': selectedTab == index }"
            :aria-labelledby="`top-${index}-tab`"
          >
            <p v-for="(paragraph, indexParagrahp) in description.paragraphs" :key="indexParagrahp">
              {{ paragraph }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['hotel_data'],
  data() {
    return {
      selectedTab: 0,
    };
  },
};
</script>
