<template>
  <div class="col-xl-4">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mb-0">My Profile</h4>
        <div class="card-options">
          <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"
            ><i class="fe fe-chevron-up"></i></a
          ><a class="card-options-remove" href="javascript:void(0)" data-bs-toggle="card-remove"
            ><i class="fe fe-x"></i
          ></a>
        </div>
      </div>
      <div class="card-body">
        <form v-if="userData">
          <div class="row mb-2">
            <div class="profile-title">
              <div class="media">
                <img class="img-70 rounded-circle" alt="" src="@/assets/images/user/7.jpg" />
                <div class="media-body">
                  <router-link to="/users/profile">
                    <h5 class="mb-1">{{ userData.name }}</h5>
                  </router-link>
                  <p v-if="userData.company">{{ userData.company }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Email-Address</label>
            <input class="form-control" v-model="userData.email" placeholder="your-email@domain.com" />
          </div>
          <div class="mb-3">
            <label class="form-label">Password</label>
            <input class="form-control" v-model="userData.password" type="password" value="password" />
          </div>
          <div class="mb-3">
            <label class="form-label">Website</label>
            <input class="form-control" v-model="userData.website" placeholder="https://trustluxury.fr" />
          </div>
          <div class="form-footer">
            <button :disabled="isPending" @click="updateUser" class="btn btn-primary btn-block" type="button">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      isPending: false,
    };
  },
  name: 'sidePage',
  props: ['userData'],
  methods: {
    ...mapActions(['updateUserProfile']),
    updateUser() {
      this.isPending = true;
      this.updateUserProfile(this.userData).then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.$toast.add({
            severity: 'success',
            summary: 'Update Success',
            life: 4000,
          });
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Error Server',
            life: 4000,
          });
        }
        this.isPending = false;
      });
    },
  },
};
</script>
