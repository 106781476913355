import axios from 'axios';
import { API_URL } from '../constants/config';
import { createToaster } from '@meforma/vue-toaster';
const toast = createToaster();

const API = axios.create({
  baseURL: API_URL,
  defaults: {
    headers: {
      common: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      'Content-type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Accept-Encoding': 'gzip, deflate',
    },

    withCredentials: true,
  },
});

API.interceptors.request.use(
  (config) => {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.access_token) {
      config.headers['Authorization'] = `Bearer ${user.access_token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    if (response.data.status === 'error') {
      toast.error('An error occurred while processing your request.<br>Please try again later or contact our support team.', {
        position: 'top-right',
      });
    }
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      //this.$swal('Session Expiré!');
      window.location = '/authentication/login';
      localStorage.clear();
      return Promise.reject(error);
    } else {
      return Promise.reject(error.response);
    }
  }
);

export { API };
