<template>
  <Breadcrumbs :main="$t('booking.BookingList')" title="Liste" />

  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body py-3">
            <div class="row">
              <div class="col-lg-3">
                <label class="form-label" for="statusFilterSelect">Status</label>
                <select
                  id="statusFilterSelect"
                  v-model="selectedStatus"
                  class="form-control select-filter"
                  @change="doFilter()">
                  <option value=""></option>
                  <option v-for="(status, index) of bookingStatus" :key="index" :value="status">
                    {{ status.name }}
                  </option>
                </select>
              </div>
              <div class="col-lg-6">
                <label class="form-label" for="statusFilterSelect">Date</label>
                <Datepicker1
                  class="datepicker-here form-control digits"
                  :multi="true"
                  v-model="date"
                  range
                  :auto-apply="true"
                  data-language="en"
                  :enable-time-picker="false"
                  :disabled-dates="highlightedDates"
                  @update:model-value="handleDate" />
              </div>
              <div class="col-lg-2">
                <button
                  :disabled="!params.status && !params.from_date"
                  class="btn btn-primary btn-pill btn-outline px-3 mt-4"
                  @click="resetFilter()">
                  {{ $t('button.removeFilter') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="card-header py-4">
              <div class="row">
                <div class="ms-auto col-md-4">
                  <div class="d-flex p-input-icon-right p-float-label align-items-center">
                    <InputText
                      id="search"
                      type="text"
                      class="w-100"
                      v-model="searchText"
                      autocomplete="off"
                      @keyup.enter="bookingSearch()" />
                    <span class="search-icons">
                      <i v-if="searchText.length" class="pi pi-times-circle" @click="resetSearch()" />
                      <i v-else-if="!loadSearch" class="pi pi-search" @click="bookingSearch()" />
                      <i v-else class="pi pi-spin pi-spinner" />
                    </span>
                    <label for="search">Search</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div v-if="!bookingLoading">
                <div v-if="bookingList.length">
                  <orders v-if="bookingList.length" :bookingList="bookingList" />
                  <Paginator
                    v-if="meta.total > 10"
                    class="my-3"
                    :rows="meta.per_page"
                    :totalRecords="meta.total"
                    :first="meta.from"
                    :rowsPerPageOptions="[10, 20, 30]"
                    @page="onPage($event)"></Paginator>
                </div>
                <div v-else class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3>{{ $t('booking.noBookingYet') }}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="card">
                <div class="card-body">
                  <div class="loader-box"><div class="loader-17"></div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import orders from './orders.vue';
import Paginator from 'primevue/paginator';
import { bookingStatus } from '@/constants/booking-status';
import Datepicker1 from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { mapActions } from 'vuex';
import moment from 'moment';
export default {
  data() {
    return {
      bookingLoading: false,
      bookingList: {},
      meta: {},
      params: {
        limit: 10,
        page: 1,
      },
      searchText: '',
      loadSearch: false,
      bookingStatus: bookingStatus,
      selectedStatus: '',
      date: [this.startDate, this.endDate],
    };
  },
  components: {
    orders,
    Paginator,
    Datepicker1,
  },
  methods: {
    ...mapActions({ getBookingList: 'booking/getBookingList' }),
    bookingSearch() {
      if (this.searchText.length > 2) {
        this.loadSearch = true;
        this.params.q = this.searchText;
        this.refreshList();
      }
    },
    resetSearch() {
      if (this.searchText.length > 2) {
        this.searchText = '';
        this.params.q = '';
        this.refreshList();
      } else {
        this.params.q = '';
        this.searchText = '';
      }
    },
    resetFilter() {
      this.params = {
        limit: 10,
        page: 1,
      };
      this.selectedStatus = '';
      this.date = [this.startDate, this.endDate];
      this.refreshList();
    },
    doFilter() {
      this.params = {
        ...this.params,
        status: this.selectedStatus.id,
      };

      this.refreshList();
    },
    handleDate(modelData) {
      this.date.value = modelData;
      this.params = {
        ...this.params,
        from_date: moment(this.date.value[0]).format('YYYY-MM-DD'),
        to_date: moment(this.date.value[1]).format('YYYY-MM-DD'),
      };
      this.refreshList();
    },
    refreshList() {
      this.bookingLoading = true;
      this.getBookingList(this.params).then((res) => {
        this.bookingList = res.data;
        this.meta = res.meta;
        this.bookingLoading = false;
        this.loadSearch = false;
      });
    },
    onPage(event) {
      this.params.page = event.page + 1;
      this.params.limit = event.rows;
      //event.page: New page number
      //event.first: Index of first record
      //event.rows: Number of rows to display in new page
      //event.pageCount: Total number of pages
      this.refreshList();
    },
  },
  created() {
    this.refreshList();
  },
};
</script>
<style lang="scss" scoped>
.search-icons {
  margin-left: -30px;
  cursor: pointer;
}
.form-control {
  height: 43px;
  padding: 0.3rem 0.7rem;
  &:focus {
    box-shadow: none;
  }
}
</style>
