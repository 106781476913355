<template>
  <Breadcrumbs title="User Cards" main="Users" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2 ms-auto d-flex mb-3">
        <a
          class="btn btn-primary ms-auto px-2"
          href="javascript:void(0)"
          data-bs-toggle="modal"
          data-bs-target="#addUserModal"
          ><i class="pi pi-user-plus me-2"></i> {{ $t('button.addUser') }}
        </a>
      </div>
    </div>

    <AddUserModal />
    <div class="row" v-if="!isLoadUsers">
      <card1 :card="allUsers" @selectUser="getIdUser" />
    </div>
    <div
      class="modal fade modal-deleteUser"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <validation-modal
        :title="'Delete'"
        :message="'Voulez vous vraiment supprimer cet élément ?'"
        @validationModal="deleteUserValidation" />
    </div>
    <div
      class="modal fade modal-blockUser"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <validation-modal
        :title="'Update Status User'"
        :message="'Voulez vous vraiment mettre à jour l\'utilisateur ?'"
        @validationModal="updateStatusUser" />
    </div>
    <div
      class="modal fade modal-validRoleUser"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <validation-modal
        :title="'Update Role User'"
        :message="'Voulez vous vraiment mettre à jour l\'utilisateur Prime ?'"
        @validationModal="updateRoleUser" />
    </div>
    <Toast />
  </div>
</template>

<script>
import card1 from './userCard.vue';
import { mapActions, mapGetters } from 'vuex';
import validationModal from '@/components/validationModal.vue';
import AddUserModal from './addUserModal.vue';

export default {
  components: {
    card1,
    validationModal,
    AddUserModal,
  },
  data() {
    return {
      selectedUser: '',
    };
  },
  methods: {
    ...mapActions(['getAllUsers', 'deleteUser', 'updateStatus', 'updateUserProfile']),
    getIdUser(userData) {
      this.selectedUser = userData;
    },
    deleteUserValidation(validation) {
      if (validation) {
        this.deleteUser(this.selectedUser.id).then(() => {
          this.$toast.add({
            severity: 'warning',
            summary: 'Delete Success',
            life: 4000,
          });
        });
      }
      this.selectedUser = '';
    },
    updateStatusUser(validation) {
      if (validation) {
        let params = {
          idUser: this.selectedUser.id,
          status: this.selectedUser.status === 1 ? 2 : 1,
          sentEmail: true,
        };
        this.updateStatus(params).then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$toast.add({
              severity: 'success',
              summary: 'Update Success',
              life: 4000,
            });
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error Server',
              life: 4000,
            });
          }
        });
      }
      this.selectedUser = '';
    },
    updateRoleUser(validation) {
      if (validation) {
        this.selectedUser.role_id = this.selectedUser.role_id === 3 ? 2 : 3;
        this.updateUserProfile(this.selectedUser).then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$toast.add({
              severity: 'success',
              summary: 'Update Success',
              life: 4000,
            });
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error Server',
              life: 4000,
            });
          }
        });
      }
      this.selectedUser = '';
    },
  },
  created() {
    this.getAllUsers();
  },
  computed: {
    ...mapGetters({
      isLoadUsers: 'isLoadUsers',
      allUsers: 'allUsers',
    }),
  },
};
</script>
