<template>
  <div class="panel-dropdown form-group" :class="{ active: isActive }">
    <span>{{ rooms.length }} {{ $t('wording.rooms') }} {{ $t('wording.for') }} </span>
    <a class="cursor-pointer" @click="myFilter"
      ><span class="qtyTotal" :class="{ 'rotate-x': rotate }">{{ countGuests }}</span> {{ $t('wording.guests') }}
    </a>
    <div class="panel-dropdown-content">
      <div class="panel-dropdown-content__room" v-for="(room, index) in rooms" :key="index">
        <h6 class="my-0">
          {{ $t('wording.room') }} {{ index + 1 }}
          <a
            v-if="rooms.length > 1"
            class="text-danger panel-dropdown-content__room-delete-btn"
            href="javascript:void(0)"
            @click="removeRoom(index)"
            ><i class="fa fa-trash"></i> {{ $t('button.delete') }}</a
          >
        </h6>
        <hr class="my-2" />
        <div class="qtyButtons">
          <label>{{ $t('wording.adults') }}</label>
          <div class="qtyDec" @click="room.adults--" :class="{ disabled: room.adults < 1 }"></div>
          <input type="text" name="qtyInput" v-model="room.adults" min="0" />
          <div class="qtyInc" @click="room.adults++" :class="{ disabled: room.adults >= 6 }"></div>
        </div>
        <div class="qtyButtons">
          <label
            >{{ $t('wording.childrens') }} <small>{{ $t('wording.children-age') }}</small></label
          >
          <div class="qtyDec" @click="room.children.length--" :class="{ disabled: room.children.length < 1 }"></div>
          <input type="text" name="qtyInput" v-model="room.children.length" min="0" />
          <div class="qtyInc" @click="room.children.push(0)" :class="{ disabled: room.children.length >= 4 }"></div>
        </div>
        <div v-for="(item, indexChild) in room.children" :key="indexChild" class="old-children d-inline-flex me-2 mb-2">
          <select v-model="room.children[indexChild]" class="form-control select-filter">
            <option disabled value="0">Age</option>
            <option v-for="(age, index) of 17" :key="index" :value="age">{{ age }}</option>
          </select>
        </div>
      </div>
      <button class="btn btn-primary btn-sm px-3" type="button" @click="addRoom()">
        <i class="fa fa-plus"></i> {{ $t('wording.addRoom') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      rotate: false,
      rooms: [{ adults: 1, children: [] }],
    };
  },
  computed: {
    countGuests() {
      return this.rooms?.map((room) => room.adults + room.children.length).reduce((a, b) => a + b, 0);
    },
  },
  methods: {
    addRoom() {
      this.rooms.push({ adults: 1, children: [] });
    },
    removeRoom(index) {
      if (this.rooms.length > 1) {
        this.rooms.splice(index, 1);
      }
    },
    myFilter() {
      this.isActive = !this.isActive;
      this.$emit('change-room', this.rooms);
    },
  },
  created() {
    const query = this.$route.query;
    if (query.guests) {
      this.rooms = JSON.parse(query.guests);
    }
  },
};
</script>

<style lang="scss" scoped></style>
