<template>
  <div class="container-fluid bg-login">
    <LoginLogo />
    <div class="row">
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div class="login-main login-form-card">
              <form class="theme-form">
                <h2 class="f-w-700 txt-primary">{{ $t('wording.authTitle2') }}</h2>
                <h5 class="f-w-700">{{ $t('wording.createPassword') }}</h5>
                <label class="col-form-label">New Password</label>
                <div class="form-group">
                  <input
                    class="form-control"
                    :type="type"
                    v-model="password"
                    name="password"
                    required
                    placeholder="*********" />
                  <div class="show-hide" @click="showPassword"><span class="show"></span></div>
                </div>
                <label class="col-form-label">Retype Password</label>
                <div class="form-group mb-5">
                  <input
                    class="form-control"
                    :type="type2"
                    v-model="retypePassword"
                    name="retypePassword"
                    required
                    placeholder="*********" />
                  <div class="show-hide" @click="showRetypePassword"><span class="show"></span></div>
                </div>
                <div class="form-group mb-0">
                  <button
                    id="btn-sign-in"
                    class="btn btn-primary btn-block w-100 login-btn btn-pill"
                    type="button"
                    :disabled="submitted"
                    @click="setPasswordUser">
                    {{ $t('wording.done') }}
                  </button>
                </div>
                <p class="mt-4 mb-0 text-center">
                  Already have an account?
                  <router-link class="ms-2" :to="{ name: 'LoginValidate' }"> Login </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import LoginLogo from '@/components/header/LoginLogo.vue';
import { mapActions } from 'vuex';
export default {
  components: { LoginLogo },
  name: 'register',
  data() {
    return {
      email: '',
      password: '',
      retypePassword: '',
      type: 'password',
      type2: 'password',
      submitted: false,
      token: '',
    };
  },
  methods: {
    ...mapActions(['setPassword']),
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    showRetypePassword() {
      if (this.type2 === 'password') {
        this.type2 = 'text';
      } else {
        this.type2 = 'password';
      }
    },
    setPasswordUser() {
      this.submitted = true;
      if (!this.password || !this.retypePassword) {
        this.$toast.add({
          severity: 'error',
          summary: this.$i18n.t('errors.AllRequired'),
          life: 4000,
        });
        this.submitted = false;
        return;
      } else if (this.password !== this.retypePassword) {
        this.$toast.add({
          severity: 'error',
          summary: this.$i18n.t('errors.PwdMatchError'),
          life: 4000,
        });

        this.submitted = false;
        return;
      }
      const userInfo = {
        email: this.email,
        token: this.token,
        password: this.password,
        password_confirmation: this.retypePassword,
      };
      this.setPassword(userInfo).then((res) => {
        if (!res || res.status === 400) {
          this.$toast.add({
            severity: 'error',
            summary: res.data.message,
            life: 4000,
          });
        } else {
          this.$router.push({ name: 'LoginValidate' });
        }
        this.submitted = false;
      });
    },
  },
  created() {
    this.token = this.$route.query.token;
    this.email = this.$route.query.email;
  },
};
</script>
<style scoped>
.container-fluid {
  max-height: 100vh;
  overflow: hidden;
}
</style>
