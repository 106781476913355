<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-5 col-lg-4 col">
            <h5 class="my-0">{{ $t('wording.availableRooms') }} :</h5>
            <p class="mb-0">
              {{ $t('wording.for') }}
              {{ `${ordersData.nightsCount} ${$t('wording.nights')}` }}
              {{ $t('wording.for') }}
              {{ `${ordersData.countAdults} ${$t('wording.adults')}` }}
              <span v-if="ordersData.countChildrens > 0">{{
                `${ordersData.countChildrens} ${$t('wording.childrens')}`
              }}</span>
            </p>
          </div>
          <div v-if="meals.length" class="col-md-5">
            <MultiSelect
              v-model="selectedMeals"
              @change="updateRoomWithMeals()"
              :options="meals"
              display="chip"
              placeholder="Select Meals"
              class="w-100 md:w-20rem">
              <template #value="slotProps">
                <div class="d-inline-flex align-items-center" v-for="(option, index) of slotProps.value" :key="index">
                  <span>{{ $t(`meals.${option}`) }}</span>
                  <span v-if="selectedMeals.length > 1">, &nbsp;</span>
                </div>
                <template v-if="!slotProps.value || slotProps.value.length === 0"> Select Meals </template>
              </template>
              <template #option="slotProps">
                <div class="flex align-items-center">
                  <div>{{ $t(`meals.${slotProps.option}`) }}</div>
                </div>
              </template>
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{ selectedMeals ? selectedMeals.length : 0 }}</b> item{{
                    (selectedMeals ? selectedMeals.length : 0) > 1 ? 's' : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
          </div>
        </div>
      </div>
      <div class="card-body room-card">
        <div v-if="mergedroom_data.length">
          <div v-for="(room, idexRoom) in mergedroom_data" :key="idexRoom">
            <div class="room-card__title-block d-flex">
              <div v-if="room.images[0]" class="room-card__images">
                <img
                  :src="room.images[0]"
                  alt="Image description"
                  class="img-thumbnail"
                  width="150"
                  @click="() => showImg(0, room.images)" />
                <span class="badge badge-primary">{{ room.images.length }} images</span>
              </div>
              <div v-else class="room-card__images">
                <img
                  :src="getImageUrl('logo/bg-white-trust-luxury.jpg')"
                  alt="Image description"
                  class="img-thumbnail"
                  width="150" />
                <span class="badge badge-primary">{{ $t('wording.noPictures') }}</span>
              </div>

              <div class="room-card__title ms-3">
                <h4>{{ roomDara.length + ' x ' + room.name }}</h4>
                <div class="room-card__title__guests mb-1">
                  <span
                    class="badge badge-secondary"
                    v-for="(roomGuest, indexRoomGuest) in roomDara"
                    :key="indexRoomGuest"
                    v-tooltip.top="getDisposition(roomGuest)">
                    <i v-for="adult of roomGuest.adults" :key="adult" class="fa fa-user"></i>
                    <i v-for="child of roomGuest.children" :key="child" class="fa fa-child"></i>
                  </span>
                </div>
                <span
                  v-for="(icon, indexIcon) in room.static_data.room_amenities"
                  :key="indexIcon"
                  :class="`size-20 me-2 icon-${icon}`"
                  :title="title(icon)"></span>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <table class="table">
                  <tr>
                    <th width="25%">{{ $t(`rooms.room`) }}</th>
                    <th width="15%">{{ $t(`rooms.meals`) }}</th>
                    <th width="15%">{{ $t(`rooms.cancellation`) }}</th>
                    <th width="15%">{{ $t(`rooms.netPrice`) }}</th>
                    <th width="20%">{{ $t(`rooms.paymentType`) }}</th>
                    <th width="10%"></th>
                  </tr>
                  <tr>
                    <td>
                      {{ room.name }}
                    </td>
                    <td>{{ $t(`meals.${room.meal}`) }}</td>
                    <td>
                      <strong class="text-success">
                        {{
                          room.payment_options.payment_types[0].cancellation_penalties.free_cancellation_before
                            ? $t('orders.FreeCancellationBefore') +
                              $d(
                                room.payment_options.payment_types[0].cancellation_penalties.free_cancellation_before,
                                'long'
                              )
                            : 'Non'
                        }}</strong
                      >
                    </td>
                    <td>
                      {{ room.payment_options.payment_types[0].show_amount }}
                      {{ room.payment_options.payment_types[0].show_currency_code }}
                      <span v-if="room.payment_options?.payment_types[0]?.tax_data.taxes" class="p-0">
                        <br />
                        {{ $t('orders.onTheSpot') }}
                        {{ room.payment_options?.payment_types[0]?.tax_data.taxes[0].amount }}
                        {{ room.payment_options?.payment_types[0]?.tax_data.taxes[0].currency_code }}
                      </span>
                    </td>
                    <td>{{ $t(`paymentTypes.${room.payment_options.payment_types[0].type}`) }}</td>
                    <td>
                      <button class="btn btn-primary px-3" @click="booking(room)">{{ $t('button.choose') }}</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="gallery my-gallery card-body row" itemscope="">
              <figure
                v-for="(src, index) in room.images"
                :key="index"
                class="col-xl-3 col-md-4 col-6"
                @click="() => showImg(index, room.images)"></figure>
            </div>
          </div>
          <!-- <div v-for="(group, category) in groupedRoom" :key="category">
            <div v-for="item in group" :key="item.id">
              <h3>{{ item.name_struct.main_name }} ({{ group.length }})</h3>
              {{ item.room_amenities }}
              {{ item.rg_ext }}
              <div class="row">
                <div class="col-md-4">
                  {{ item.arrangements?.meal }}
                </div>
              </div>
              <div class="gallery my-gallery card-body row" itemscope="">
                <figure
                  v-for="(src, index) in item.images"
                  :key="index"
                  class="col-xl-3 col-md-4 col-6"
                  @click="() => showImg(index, item.images)"
                >
                  <img :src="src" alt="Image description" class="img-thumbnail" />
                </figure>
              </div>
            </div>
          </div> -->
          <vue-easy-lightbox :visible="visible" :index="index" :imgs="lightBoxImages" @hide="handleHide">
          </vue-easy-lightbox>
        </div>
        <div v-else>
          <h5 class="my-0">{{ $t('wording.noAvailableRooms') }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import getImage from '@/mixins/getImage';

export default {
  name: 'HotelRoomsList',
  props: ['roomsData', 'arrangementsRoom', 'hotelBaseData', 'ordersData'],
  mixins: [getImage],
  data() {
    return {
      groupedRoom: {},
      mergedroom_data: {},
      fullMergedroom_data: {},
      lightBoxImages: [],
      selectedMeals: null,
      meals: [],
      cancelPolicy: [],
      visible: false,
      index: '',
      roomDara: '',
    };
  },
  methods: {
    ...mapActions({
      storeOrderTmpByHash: 'orders/storeOrderTmpByHash',
    }),
    getDisposition(roomGuests) {
      let msgTooltip = '';
      if (roomGuests.adults) {
        msgTooltip += `${roomGuests.adults} ${this.$t('wording.adults')} `;
      }
      if (roomGuests.children.length) {
        msgTooltip += `${roomGuests.children.length} ${this.$t('wording.childrens')}`;
      }
      return msgTooltip;
    },
    updateRoomWithMeals() {
      if (this.selectedMeals.length > 0) {
        this.mergedroom_data = this.fullMergedroom_data.filter((room) => this.selectedMeals.includes(room.meal));
      } else {
        this.mergedroom_data = this.fullMergedroom_data;
      }
    },
    booking(room_data) {
      const query = this.$route.query;
      const hotelId = this.$route.params.hotelId;
      const params = {
        book_hash: room_data.book_hash,
        order_information: {
          ...query,
          guests: JSON.parse(query.guests),
          room_data,
          hotel_data: this.hotelBaseData,
        },
      };
      const searchData = {
        ...query,
        id: hotelId,
      };
      this.storeOrderTmpByHash(params).then(() => {
        this.$store.commit('orders/setRoomHotel', room_data);
        this.$router.push({ name: 'makeReservation', params: { bookHash: room_data.book_hash }, query: searchData });
      });
    },
    showImg(index, imgs) {
      this.index = index;
      this.visible = true;
      this.lightBoxImages = [...imgs];
    },
    handleHide() {
      this.visible = false;
    },
    imageClick(index) {
      this.activeIndex = index;
      this.displayCustom = true;
    },
    /* groupRoomsByGroupId() {
      this.groupedRoom = this.updatedRoomsData?.reduce((acc, cur) => {
        const category = cur.room_group_id;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(cur);
        return acc;
      }, {});
    }, */
    title(title) {
      return title
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
  },
  created() {
    this.roomDara = JSON.parse(this.$route.query.guests);
    const roomsMerged = [];
    if (this.arrangementsRoom) {
      this.arrangementsRoom?.rates?.map((room) => {
        const indexMeal = this.meals.findIndex((meal) => meal === room.meal);
        if (indexMeal === -1) {
          this.meals.push(room.meal);
        }
        this.roomsData?.map((staticRoom) => {
          const roomKeys = Object.keys(room.rg_ext).sort();
          const staticRoomKeys = Object.keys(staticRoom.rg_ext).sort();
          if (
            JSON.stringify(roomKeys.reduce((acc, key) => ({ ...acc, [key]: room.rg_ext[key] }), {})) ===
            JSON.stringify(staticRoomKeys.reduce((acc, key) => ({ ...acc, [key]: staticRoom.rg_ext[key] }), {}))
          ) {
            roomsMerged.push({
              ...room,
              name: staticRoom.name,
              images: staticRoom.images?.map((image) => image.replace('{size}', '1024x768')) || [],
              static_data: staticRoom,
            });
          }
        });
      });
      this.mergedroom_data = roomsMerged;
      this.fullMergedroom_data = roomsMerged;
    }
  },
};
</script>
<style scoped lang="scss">
.table th {
  font-weight: 700;
}
.room-card {
  .btn {
    color: #fff;
  }
  &__images {
    position: relative;
    cursor: pointer;
    .badge {
      position: absolute;
      bottom: 7px;
      left: 7px;
    }
  }
  i {
    &:not(:first-child) {
      margin-right: 0.1rem;
      margin-left: 0.1rem;
    }
    &:first-child {
      margin-right: 0.1rem;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
