const bookingMixin = {
  methods: {
    getClassStatus(status) {
      let classList = '';
      switch (status) {
        case 1:
        case 'pending':
          classList = 'badge-warning';
          break;
        case 2:
        case 'unpaid':
          classList = 'badge-secondary';
          break;
        case 3:
        case 'payed':
          classList = 'badge-success';
          break;
        case 4:
        case 'canceled':
          classList = 'badge-danger';
          break;
        case 5:
        case 'completed':
          classList = 'badge-info';
          break;
        default:
          classList = 'badge-primary';
          break;
      }
      return classList;
    },
  },
};

export default bookingMixin;
