<template>
  <Breadcrumbs :main="$t('menu.Booking') + ' N° ' + booking.num_reservation" title="Details" />

  <div class="container-fluid">
    <div v-if="!isLoading" class="row">
      <div class="col-md-4 order-1">
        <div class="card">
          <div class="card-header">
            <h4>{{ booking?.hotel_name }}</h4>
            <p>{{ booking?.address }}</p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                {{ $t('orders.checkIn') }} <br />
                {{ booking.checkin }} <br />
                {{ $t('orders.from') }} {{ booking.check_in_time }}
              </div>
              <div class="col-6">
                {{ $t('orders.checkOut') }} <br />
                {{ booking.checkout }} <br />
                {{ $t('orders.to') }} {{ booking.check_out_time }}
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <div v-if="cancellationRoom" class="text-danger f-w-700">
                  {{ $t('orders.FreeCancellationBefore') }}
                  {{ $d(cancellationRoom, 'long') }}
                </div>
              </div>
              <div class="col-12">
                <span v-if="booking.cancellationRoom">
                  {{ $t('orders.FreeCancellationBefore') }}
                  {{ $d(booking.cancellationRoom, 'long') }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="clientData" class="card">
          <div class="card-header d-flex justify-content-between">
            <h4>Contact</h4>
          </div>
          <div class="card-body" v-if="booking.order_details">
            <div class="row">
              <div class="col mt-2">
                <dl>
                  <dd class="ms-3">
                    <i class="fa fa-user txt-primary m-r-5"></i>
                    {{ $t('user.name') + ' : ' + clientData.first_name + ' ' + clientData.last_name }}
                  </dd>
                  <dd class="ms-3">
                    <i class="fa fa-at txt-primary m-r-5"></i>
                    {{ $t('user.email') + ' : ' + clientData.email }}
                  </dd>
                  <dd class="ms-3">
                    <i class="fa fa-phone txt-primary m-r-5"></i>
                    {{ $t('user.phone') + ' : ' + clientData.phone }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 order-md-1 order-2">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h4>
              {{ orderDetails?.rooms.length + ' x ' + $t('wording.rooms') }} -
              <span class="txt-secondary f-w-600">{{ $t(`meals.${room_data?.meal}`) }}</span>
            </h4>
            <h5 class="booking__status ml-auto">
              <span class="badge rounded-pill" :class="getClassStatus(booking.status)">{{
                $t(`booking.status.${booking.status}`)
              }}</span>
            </h5>
          </div>
          <div class="card-body" v-if="booking.order_details?.guests">
            <div class="row" v-for="(room, indexRoom) in booking.order_details.guests" :key="indexRoom">
              <div class="col-12">
                <h5>
                  {{ room_data?.name }}
                </h5>
              </div>
              <div class="col mt-2">
                <dl>
                  <dt>
                    {{ room.adults }} {{ $t('wording.adults') }}
                    <span v-if="room.children.length">
                      {{ $t('wording.and') }} {{ room.children.length }} {{ $t('wording.childrens') }}</span
                    >
                  </dt>
                  <dd
                    class="ms-3"
                    v-for="(guests, indexGuest) in orderDetails?.rooms[indexRoom].guests"
                    :key="indexGuest">
                    <i class="fa fa-angle-double-right txt-primary m-r-5"></i>
                    {{ guests.first_name }}
                    {{ guests.last_name }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-if="booking.histories.length" class="col-lg-7">
            <div class="card">
              <div class="card-header">
                <h4>Historique</h4>
              </div>
              <div class="card-body">
                <div class="mb-2 d-flex gap-3 flex-wrap" v-for="(history, index) in booking.histories" :key="index">
                  <span class="badge rounded-pill" :class="getClassStatus(history.action)">{{
                    $t(`booking.status.${history.action}`)
                  }}</span>
                  <span> {{ $t(`booking.at`) + $d(history.action_at, 'longTime') }}</span>
                  <span>
                    {{ $t(`booking.by`) }} <strong>{{ history.action_by }}</strong></span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-6">
                    <h4>{{ $t('wording.totalPrice') }}</h4>
                  </div>
                  <div class="col-6 d-flex">
                    <h4>
                      {{ booking.amount }}
                      {{ booking.currency }}
                    </h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p v-if="booking.guests" class="mb-0">
                      {{ $t('orders.PriceOf') }} {{ booking.guests.length }} {{ $t('wording.rooms') }} pour
                      {{ numberOfNights }} {{ $t('wording.nights') }} <br />
                      {{ $t('orders.VATnotApplicable') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <p class="text-danger">{{ $t('wording.city_tax_description') }}</p>
                <div class="row">
                  <div class="col-6">{{ $t('wording.city_tax') }}</div>
                  <div class="col-6 d-flex">
                    {{ booking.amount }}
                    {{ booking.currency }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import bookingMixin from '@/mixins/bookingMixin';

export default {
  mixins: [bookingMixin],
  data() {
    return {
      isLoading: false,
      booking: {},
      room_data: {},
      orderDetails: {},
      clientData: {},
    };
  },
  computed: {
    cancellationRoom() {
      return this.room_data?.payment_options?.payment_types[0]?.cancellation_penalties.free_cancellation_before;
    },
  },
  methods: {
    ...mapActions({ getBookingById: 'booking/getBookingById' }),
  },
  created() {
    this.isLoading = true;
    this.getBookingById(this.$route.params.bookId).then((res) => {
      this.booking = res.data;
      this.room_data = this.booking.order_details.room_data;
      this.clientData = this.booking.order_details.client;
      this.orderDetails = res.data.order_details.order_details;
      this.isLoading = false;
    });
  },
};
</script>
