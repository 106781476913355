<template>
  <div class="col-md-8 box-col-12">
    <div class="card">
      <div class="card-body">
        <Swiper
          :slidesPerView="1"
          :loop="true"
          :thumbs="{ swiper: thumbsSwiper }"
          :autoplay="{ delay: 3500, disableOnInteraction: false }"
          :spaceBetween="20"
          :centeredSlides="true"
          :modules="modules"
          dir="rtl"
          class="mySwiper"
        >
          <swiper-slide v-for="(image, index) in listImages" :key="index">
            <img :src="image" :id="index" class="img-fluid bg-img" alt="index" />
          </swiper-slide>
        </Swiper>
        <Swiper
          @swiper="setThumbsSwiper"
          :loop="true"
          :slidesPerView="4"
          :spaceBetween="5"
          :watchSlidesProgress="true"
          :pagination="{
            clickable: true,
          }"
          :modules="modules"
          dir="rtl"
          class="Swiper mt-2"
        >
          <swiper-slide v-for="(image, index) in listImages" :key="index">
            <img :src="image" :id="index" class="img-fluid bg-img" alt="index" style="height: auto" />
          </swiper-slide>
        </Swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import hotelPage from '@/mixins/hotelPage';
import 'swiper/css';
import { Autoplay, FreeMode, Navigation, Thumbs, Pagination } from 'swiper';

export default {
  mixins: [hotelPage],
  props: ['hotel_data'],
  name: 'hotelPage',
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    listImages() {
      return this.hotel_data.images?.map((image) => image.replace('{size}', '1024x768'));
    },
  },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    return {
      modules: [Autoplay, Navigation, FreeMode, Thumbs, Pagination],
      thumbsSwiper,
      setThumbsSwiper,
    };
  },
  methods: {
    filled(len) {
      this.selectedStars = len;
    },
    addToCart: function (product, qty) {
      product.quantity = qty ? qty : 1;
      this.$store.dispatch('product/addToCart', product);
    },
  },
};
</script>
<style scoped>
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.mySwiper img {
  max-height: 500px;
}
.Swiper img {
  max-height: 200px;
}
</style>
