<template>
  <div class="container-fluid bg-login">
    <LoginLogo />
    <div class="row">
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div class="login-main login-form-card">
              <form class="theme-form">
                <h4>Reset Your Password</h4>
                <h6 class="mt-4">Create Your Password</h6>
                <div class="form-group">
                  <label class="col-form-label">New Password</label>
                  <input
                    class="form-control"
                    type="password"
                    name="login[password]"
                    required=""
                    placeholder="*********" />
                  <div class="show-hide"><span class="show"></span></div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Retype Password</label>
                  <input
                    class="form-control"
                    type="password"
                    name="login[password]"
                    required=""
                    placeholder="*********" />
                </div>
                <div class="form-group mb-0">
                  <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox" />
                    <label class="text-muted" for="checkbox1">Remember password</label>
                  </div>
                  <button :disabled="submitted" class="btn btn-primary btn-block" type="submit">Done</button>
                </div>
                <p class="mt-4 mb-0">
                  Already have an password?
                  <router-link class="ml-2" to="/auth/login"> Login </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import LoginLogo from '@/components/header/LoginLogo.vue';
import { mapActions } from 'vuex';
export default {
  components: { LoginLogo },
  name: 'register',
  data() {
    return {
      email: '',
      password: '',
      retypePassword: '',
      type: 'password',
      type2: 'password',
      submitted: false,
    };
  },
  methods: {
    ...mapActions(['setPassword']),
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    showRetypePassword() {
      if (this.type2 === 'password') {
        this.type2 = 'text';
      } else {
        this.type2 = 'password';
      }
    },
    setPasswordUser() {
      this.submitted = true;
      if (!this.password || !this.retypePassword) {
        this.$toast.add({
          severity: 'error',
          summary: this.$i18n.t('errors.AllRequired'),
          life: 4000,
        });

        this.submitted = false;
        return;
      }
      const userInfo = {
        email: this.email,
        password: this.password,
        password_confirmation: this.retypePassword,
      };
      this.setPassword(userInfo).then((res) => {
        if (!res) {
          this.$toast.add({
            severity: 'error',
            summary: this.$i18n.t('errors.LoginFailed'),
            life: 4000,
          });
        }
        this.submitted = false;
      });
    },
  },
};
</script>
<style scoped>
.container-fluid {
  max-height: 100vh;
  overflow: hidden;
}
</style>
