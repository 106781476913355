<template>
  <div class="container-fluid">
    <div class="row starter-main">
      <div class="col-sm-12">
        <div class="card search-box">
          <div class="card-body">
            <form @submit="checkForm" method="post">
              <div class="row no-gutters version_3">
                <div class="col-lg-3 mb-2 mb-lg-0 mb-2">
                  <Multicomplete @change-city="updateSelected" />
                </div>
                <div class="col mb-2 mb-lg-0 mb-2 px-lg-0">
                  <Calendar v-model:checkIn="checkIn" v-model:checkOut="checkOut" />
                </div>
                <div class="col mb-2 mb-lg-0 mb-3 pe-lg-1">
                  <div class="panel-dropdown form-group" :class="{ active: isActive }">
                    <div class="d-flex align-items-center gap-2 h-100" @click="myFilter">
                      <vue-feather type="user"></vue-feather>
                      <div class="w-100">
                        <a class="cursor-pointer"
                          ><span class="qtyTotal me-1" :class="{ 'rotate-x': rotate }">{{ countGuests }}</span
                          >{{ $t('wording.guests') }},
                          <span>
                            <strong>{{ rooms.length }} </strong> {{ $t('wording.rooms') }}
                          </span>
                        </a>
                      </div>
                    </div>

                    <div class="panel-dropdown-content">
                      <div class="panel-dropdown-content__room" v-for="(room, index) in rooms" :key="index">
                        <h6 class="my-0">
                          {{ $t('wording.room') }} {{ index + 1 }}
                          <a
                            v-if="rooms.length > 1"
                            class="text-danger panel-dropdown-content__room-delete-btn"
                            href="javascript:void(0)"
                            @click="removeRoom(index)"
                            ><i class="fa fa-trash"></i> {{ $t('button.delete') }}</a
                          >
                        </h6>
                        <hr class="my-2" />
                        <div class="qtyButtons">
                          <label>{{ $t('wording.adults') }}</label>
                          <div class="qtyDec" @click="room.adults--" :class="{ disabled: room.adults < 1 }"></div>
                          <input type="text" name="qtyInput" v-model="room.adults" min="0" />
                          <div class="qtyInc" @click="room.adults++" :class="{ disabled: room.adults >= 6 }"></div>
                        </div>
                        <div class="qtyButtons">
                          <label
                            >{{ $t('wording.childrens') }} <small>{{ $t('wording.children-age') }}</small></label
                          >
                          <div
                            class="qtyDec"
                            @click="room.children.length--"
                            :class="{ disabled: room.children.length < 1 }"></div>
                          <input type="text" name="qtyInput" v-model="room.children.length" min="0" />
                          <div
                            class="qtyInc"
                            @click="room.children.push(0)"
                            :class="{ disabled: room.children.length >= 4 }"></div>
                        </div>
                        <div
                          v-for="(item, indexChild) in room.children"
                          :key="indexChild"
                          class="old-children d-inline-flex me-2 mb-2">
                          <select v-model="room.children[indexChild]" class="form-control select-filter">
                            <option disabled value="0">Age</option>
                            <option v-for="(age, index) of 17" :key="index" :value="age">{{ age }}</option>
                          </select>
                        </div>
                      </div>
                      <button class="btn btn-primary btn-sm px-3" type="button" @click="addRoom()">
                        <i class="fa fa-plus"></i> {{ $t('wording.addRoom') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 mb-2 mb-md-0">
                  <input
                    type="submit"
                    class="btn btn-light-primary btn-pill search-box__btn-search"
                    :value="$t('button.search')" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
  <!-- </div> -->
</template>
<script>
import { Calendar } from 'vue-calendar-3';
import Multicomplete from '@/components/search/MultiComplete.vue';
import moment from 'moment';

export default {
  components: {
    Calendar,
    Multicomplete,
  },
  data() {
    return {
      isActive: false,
      rotate: false,
      nightCount: 0,
      ageChildren: [],
      rooms: [{ adults: 1, children: [] }],
      checkIn: new Date(),
      checkOut: new Date(new Date().getTime() + 86400000),
      selectedCity: '',
    };
  },
  computed: {
    countGuests() {
      return this.rooms?.map((room) => room.adults + room.children.length).reduce((a, b) => a + b, 0);
    },
  },
  methods: {
    addRoom() {
      this.rooms.push({ adults: 1, children: [] });
    },
    removeRoom(index) {
      if (this.rooms.length > 1) {
        this.rooms.splice(index, 1);
      }
    },
    updateSelected(value) {
      this.selectedCity = value;
    },
    myFilter() {
      this.isActive = !this.isActive;
    },
    nightsCount(startDate, endDate) {
      const date1 = new Date(startDate);
      const date2 = new Date(endDate);
      const timeDiff = Math.abs(date2.getTime() - date1.getTime());
      const nightsCount = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return nightsCount;
    },
    checkForm: function (e) {
      if (this.selectedCity) {
        let searchData = {
          checkin: moment(this.checkIn).format('YYYY-MM-DD'),
          checkout: moment(this.checkOut).format('YYYY-MM-DD'),
          guests: JSON.stringify(this.rooms),
          region_id:
            this.selectedCity.type == 'City' || this.selectedCity.type === 'Railway Station'
              ? this.selectedCity.id
              : this.selectedCity.region_id,
          search_value: JSON.stringify(this.selectedCity),
        };
        this.nightCount = this.nightsCount(this.checkIn, this.checkOut);
        if (this.nightCount < 31) {
          this.$store.commit('setSearchData', searchData);
          if (this.selectedCity.type === 'City' || this.selectedCity.type === 'Railway Station') {
            this.$router.push({ name: 'hotelsList', query: searchData });
          } else {
            this.$router.push({ name: 'hotelInfos', params: { hotelId: this.selectedCity.id }, query: searchData });
          }
        } else {
          this.$toast.add({
            severity: 'error',
            summary: this.$i18n.t('errors.nightsExceeded'),
            life: 4000,
          });
        }
      }
      e.preventDefault();
    },
  },
};
</script>
