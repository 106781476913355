<template>
  <SearchSidebar />
  <div class="left-filter card">
    <div class="card-body filter-cards-view animate-chk">
      <div class="product-filter" v-for="(item, indexCat) in category" :key="indexCat">
        <h6 class="f-w-600">{{ item.name }}</h6>
        <div class="checkbox-animated my-1" v-for="(child, index) in item.values" :key="index">
          <label class="d-block" :for="child.id">
            <input
              class="checkbox_animated"
              :value="child.val"
              v-model="applyFilter"
              @change="onChangeCategory(applyFilter)"
              :id="child.val"
              type="checkbox"
              data-original-title=""
              title="" /><i v-for="(item, startIndex) of child.val" :key="startIndex" class="fa fa-star"></i>
          </label>
        </div>
      </div>
      <!-- <div class="product-filter slider-product">
        <h6 class="f-w-600">Colors</h6>
        <div class="color-selector">
          <ul>
            <li class="active white"></li>
            <li class="bg-primary" value="yellow" @change="selectColor($event)" :class="{ active: isActive }"></li>
            <li class="bg-secondary"></li>
            <li class="bg-success"></li>
            <li class="bg-warning"></li>
            <li class="bg-danger"></li>
            <li class="blue"></li>
            <li class="red"></li>
          </ul>
        </div>
      </div> -->
      <!-- <div class="product-filter pb-0">
        <h6 class="f-w-600">
          Price
          <input type="range" :min="min" :max="max" v-model="start" @change="setchange()" />
        </h6>
      </div> -->
    </div>
  </div>
</template>
<script>
import SearchSidebar from '@/components/search/searchSidebar.vue';
import { category } from '../../../data/ecommerce/filter';
const _ = require('lodash');
import { mapGetters } from 'vuex';
export default {
  name: 'filterPage',
  components: {
    SearchSidebar,
  },
  data() {
    return {
      min: 0,
      max: 0,
      start: 0,
      category: category,
      applyFilter: [],
      filter: false,
      isActive: false,
    };
  },
  computed: {
    ...mapGetters({ price: 'hotels/price', filterProduct: 'hotels/filterProduct' }),
  },
  mounted() {
    var vm = this,
      max = _.maxBy(this.price),
      min = _.minBy(this.price);
    vm.start = max;
    (vm.min = min), (vm.max = max);
    this.$store.dispatch('hotels/priceFilter', {
      min: this.min,
      max: this.start,
    });
  },
  methods: {
    onChangeCategory(category){
      this.$emit( 'onChangeCategory', category);
    },
    setchange() {
      this.$store.dispatch('hotels/priceFilter', {
        min: this.min,
        max: this.start,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
i.fa-star {
  font-size: 16px;
  letter-spacing: 3px;
  color: #ffa800;
}
</style>
