<template>
  <div class="card search-sidebar">
    <div class="card-header my-0">
      <h5 class="my-0">Modification</h5>
    </div>
    <div class="card-body">
      <MultiComplete class="mb-3" @change-city="updateSelected" />
      <Calendar :position="'right'" v-model:checkIn="checkIn" v-model:checkOut="checkOut" />
      <RoomSearch @change-room="updateRoom" />
      <button type="submit" @click.prevent="search()" class="btn btn-primary mt-3 d-flex mx-auto">Search</button>
    </div>
  </div>
</template>
<script>
import { Calendar } from 'vue-calendar-3';
import MultiComplete from './MultiComplete.vue';
import moment from 'moment';
import RoomSearch from './RoomSearch.vue';

export default {
  components: {
    Calendar,
    MultiComplete,
    RoomSearch,
  },
  data() {
    return {
      nightCount: 0,
      checkIn: new Date(),
      checkOut: new Date(new Date().getTime() + 172800000),
      selectedCity: '',
      rooms: [],
    };
  },
  methods: {
    updateSelected(value) {
      this.selectedCity = value;
    },
    updateRoom(value) {
      this.rooms = value;
    },
    search() {
      if (this.selectedCity) {
        let searchData = {
          checkin: moment(this.checkIn).format('YYYY-MM-DD'),
          checkout: moment(this.checkOut).format('YYYY-MM-DD'),
          guests: JSON.stringify(this.rooms),
          region_id:
            this.selectedCity.type == 'City' || this.selectedCity.type === 'Railway Station'
              ? this.selectedCity.id
              : this.selectedCity.region_id,
          search_value: JSON.stringify(this.selectedCity),
        };
        this.$store.commit('setSearchData', searchData);
        if (this.selectedCity.type === 'City' || this.selectedCity.type === 'Railway Station') {
          this.$router.push({ name: 'hotelsList', query: searchData }).then(() => {
            this.$router.go(0);
          });
        } else {
          this.$router
            .push({ name: 'hotelInfos', params: { hotelId: this.selectedCity.id }, query: searchData })
            .then(() => {
              this.$router.go(0);
            });
        }
      }
    },
  },
  created() {
    const query = this.$route.query;
    this.checkIn = Date.parse(query.checkin);
    this.checkOut = Date.parse(query.checkout);
    this.rooms = JSON.parse(query.guests);
    if (query.search_value) {
      this.selectedCity = JSON.parse(query.search_value);
    }
  },
};
</script>
