<template>
  <Breadcrumbs main="Hôtels" title="List Hôtels" />
  <div class="container-fluid product-wrapper" :class="{ sidebaron: isActive }">
    <div class="product-grid">
      <!-- <div class="feature-products">
        <div class="row">
          <div class="col-md-6 products-total">
            <span class="d-none-productlist filter-toggle" @click="disp()"
              >Filters
              <span> <vue-feather class="toggle-data" type="chevron-down"></vue-feather> </span>
            </span>
          </div>
          <sortingPage />
        </div>
        <div class="row">
          <div class="col-sm-3">
            <div class="product-sidebar" :class="{ open: isActive }">
              <div class="filter-section">
                <div class="card">
                  <div class="card-header">
                    <h6 class="mb-0 f-w-600">
                      Filters<span class="pull-right"
                        ><i class="fa fa-chevron-down toggle-data" @click.prevent="disp()"></i
                      ></span>
                    </h6>
                  </div>
                  <filterPage />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9 col-sm-12">
            <form>
              <div class="form-group m-0">
                <input class="form-control" type="search" placeholder="Search.." data-original-title="" title="" /><i
                  class="fa fa-search"
                ></i>
              </div>
            </form>
          </div>
        </div>
      </div> -->
      <div class="product-wrapper-grid list-view">
        <div class="row">
          <div class="col-lg-3"><filterPage @onChangeCategory="filterHotelList" /></div>
          <div class="col-lg-9">
            <hotelView v-if="!isLoadSearch && filtredResultSearch.length" />
            <div v-else>
              <div class="card">
                <div class="card-body">
                  <div v-if="isLoadSearch" class="loader-box"><div class="loader-17"></div></div>
                  <div v-else class="my-5 text-center">
                    <h4> {{ $t('wording.noDataSearch')}}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
//import hotelsMixin from '@/mixins/hotelsMixin';
import filterPage from './filterPage.vue';
import hotelView from './hotelView.vue';
import sortingPage from './sortingPage.vue';

export default {
  name: 'indexHotels',
  //mixins: [hotelsMixin],
  components: {
    filterPage,
    hotelView,
    sortingPage,
  },
  computed: {
    ...mapGetters(['isLoadSearch', 'filtredResultSearch']),
  },
  methods: {
    ...mapActions(['getSeachDataByRegion']),
    filterHotelList(filter) {
      this.$store.commit('filterSearchByCategories', filter);
    },
  },
  created() {
    let searchData = this.$route.query;
    let params = {
      checkin: searchData.checkin,
      checkout: searchData.checkout,
      residency: 'FR',
      language: this.$i18n.locale,
      guests: JSON.parse(searchData.guests),
      region_id: parseInt(searchData.region_id),
      currency: 'EUR',
    };
    this.$store.commit('setSearchData', params);
    this.getSeachDataByRegion(params);
  },
};
</script>
