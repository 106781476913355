import { API } from '../modules/axios';
const END_POINT = '/b2b/hotels';
const END_POINT_BOOKING = '/b2b/hotels/booking';
const END_POINT_ORDERS = '/b2b/hotels/order';
const END_POINT_PAYMENT = '/b2b/hotels/create-checkout-session';

const getHotelById = (hotelId) => API.post(END_POINT + '/info', hotelId);
const getHotelsByIds = (hotelsIds) => API.post(END_POINT + '/search/ids', hotelsIds);
const storeOrderTmpByHash = (orderData) => API.post(END_POINT_ORDERS + '/temp', orderData);
const orderForm = (orderFormData) => API.post(END_POINT_ORDERS + '/booking/form', orderFormData);
const prebookBooking = (params) => API.post(END_POINT_BOOKING + '/prebook', params);
const getOrderTmpByHash = (bookHash) => API.get(`${END_POINT_ORDERS}/temp/${bookHash}`);
const orderBookingFinish = (orderBookingData) => API.post(END_POINT_ORDERS + '/booking/finish', orderBookingData);
const createCheckoutSession = (bookingData) => API.post(END_POINT_PAYMENT, bookingData);

export const ordersService = {
  getHotelById,
  getHotelsByIds,
  storeOrderTmpByHash,
  orderForm,
  prebookBooking,
  getOrderTmpByHash,
  orderBookingFinish,
  createCheckoutSession,
};
