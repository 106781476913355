import { API } from '../modules/axios';
const END_POINT = '/b2b/hotels/booking';

const getBookingList = (params) => API.get(END_POINT + '/list', params);
const getBookingById = (bookingId) => API.get(END_POINT + `/detail/${bookingId}`);
const storeBooking = (bookingData) => API.post(END_POINT + '/create', bookingData);
const updateStatusById = (bookingId, params) => API.put(`${END_POINT}/update-status/${bookingId}`, params);
const sendBookingVoucherById = (bookingId) => API.post(END_POINT + `/send-voucher/${bookingId}`);

export const bookingService = {
  getBookingList,
  getBookingById,
  storeBooking,
  updateStatusById,
  sendBookingVoucherById
};
