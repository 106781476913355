import { API } from '../modules/axios';
const END_POINT = '/b2b/hotels';

const getSeachData = (params) => API.post(END_POINT + '/autocomplete', params);
const getSeachDataByRegion = (params) => API.post(END_POINT + '/search/region', params);

export const searchService = {
  getSeachData,
  getSeachDataByRegion,
};
