<template>
  <div class="col-xl-4 col-sm-6 col-xxl-3 col-ed-4 box-col-4" v-for="(item, index) in card" :key="index">
    <div class="card social-profile" :class="{ 'social-profile__prime': item.role_id === 2 }">
      <div class="card-body">
        <div class="social-img-wrap" :class="{ 'social-img-wrap__prime': item.role_id === 2 }">
          <div class="social-img">
            <img :src="item.avatar ? baseURL + item.avatar : getImageUrl('user/3.jpg')" alt="profile" />
          </div>
          <!-- <div class="status-icon__top">
            <span v-if="item.status === 1" class="badge rounded-pill badge-success px-1 py-1">
              <i class="pi pi-check" style="font-size: 0.7rem"></i>
            </span>
            <span v-else class="badge rounded-pill badge-warning px-1 py-1">
              <i class="pi pi-ban" style="font-size: 0.7rem"></i>
            </span>
          </div> -->
          <div class="edit-icon">
            <span
              v-if="item.role_id === 2"
              class="badge rounded-pill badge-secondary px-1 py-1"
              v-tooltip="'User Prime'">
              <i class="pi pi-star-fill" style="font-size: 0.7rem"></i>
            </span>
          </div>
        </div>
        <div class="social-details">
          <h5 class="mb-1">
            <router-link v-tooltip.top="'Modifier'" :to="{ name: 'editUser', params: { id: item.id } }">{{
              item.name
            }}</router-link>
          </h5>
          <span class="f-light">{{ item.email }}</span>
          <ul class="card-social">
            <li>
              <a
                href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target=".modal-deleteUser"
                @click="selectUserData(item)"
                v-tooltip.top="'Supprimer'"
                ><i class="fa fa-trash fa-lg text-danger"></i
              ></a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target=".modal-blockUser"
                @click="selectUserData(item)"
                v-tooltip.top="item.status == 1 ? 'Bloquer' : 'Débloquer'">
                <i v-if="item.status == 1" class="fa fa-lg fa-unlock text-success"></i>
                <i v-else class="fa fa-lock fa-lg text-danger"></i>
              </a>
            </li>
            <li>
              <router-link :to="{ name: 'editUser', params: { id: item.id } }"
                ><i class="fa fa-lg fa-edit"></i
              ></router-link>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target=".modal-validRoleUser"
                @click="selectUserData(item)">
                <span v-if="item.role_id === 2" class="badge rounded-pill badge-danger px-1 py-1">
                  <i class="pi pi-star-fill" style="font-size: 0.6rem"></i>
                </span>
                <span v-else class="badge rounded-pill badge-secondary px-1 py-1">
                  <i class="pi pi-star-fill" style="font-size: 0.6rem"></i>
                </span>
              </a>
            </li>
          </ul>
          <ul class="social-follow">
            <li>
              <h5 class="mb-0">{{ item.bookings_count }}</h5>
              <span class="f-light">{{ $t('menu.BookingList') }}</span>
            </li>
            <li>
              <h5 class="mb-0">0</h5>
              <span class="f-light">Clients</span>
            </li>
            <li>
              <h5 class="mb-0">0</h5>
              <span class="f-light">Solde</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getImage from '@/mixins/getImage';
import { baseURL } from '@/constants/config';
export default {
  data() {
    return {
      baseURL: baseURL,
    };
  },
  mixins: [getImage],
  props: ['card'],
  methods: {
    selectUserData(userData) {
      this.$emit('selectUser', userData);
    },
  },
};
</script>
