<template>
  <Breadcrumbs title="Edit Profile" main="Users" />
  <div class="container-fluid">
    <div class="edit-profile">
      <div class="row">
        <sidePage :userData="user" />
        <editProfile :userData="user" />
      </div>
    </div>
  </div>
</template>
<script>
import sidePage from './sidePage.vue';
import editProfile from './editProfile.vue';
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      user: {},
    };
  },
  components: {
    sidePage,
    editProfile,
  },
  methods: {
    ...mapActions({ getUser: 'getUser', getProfile: 'getProfile' }),
  },
  created() {
    const idUser = this.$route.params.id;
    if (idUser) {
      this.getUser(idUser).then((res) => {
        this.user = res;
      });
      let params = {
        user_id: idUser,
      };
    } else {
      this.getProfile().then((res) => {
        this.user = res;
      });
    }
  },
};
</script>
