import { searchService, hotelsService } from '../../_services';

const state = {
  searchData: {},
  resultSearch: {},
  filtredResultSearch: [],
  isLoadSearch: false,
};
// getters
const getters = {
  searchData: (state) => state.searchData,
  resultSearch: (state) => state.resultSearch,
  filtredResultSearch: (state) => state.filtredResultSearch,
  isLoadSearch: (state) => state.isLoadSearch,
  errorsSearch: (state) => state.errors,
};
const actions = {
  async searchAutocomplet({ commit }, query) {
    return await searchService.getSeachData(query).then(
      (res) => {
        commit('getAllSuccess', res.data.data);
        return res.data;
      },
      (error) => commit('getAllFailure', error)
    );
  },

  getSeachDataByRegion({ commit }, params) {
    commit('isLoadSearchStatus', true);
    return searchService.getSeachDataByRegion(params).then(
      (res) => {
        commit('getSearchSuccess', res.data.data);
        return res.data.data.hotels;
      },
      (error) => commit('getSearchFailure', error)
    );
  },

  async getHotelsDataByIds({ commit }, params) {
    return await hotelsService.getHotelsByIds(params).then(
      (res) => {
        return res.data;
      },
      (error) => commit('getHotelFailure', error)
    );
  },
};
const mutations = {
  setSearchData(state, data) {
    state.searchData = data;
  },
  getSearchSuccess(state, result) {
    state.resultSearch = result;
    state.filtredResultSearch = result.hotels;
    state.isLoadSearch = false;
  },
  getSearchFailure(state) {
    state.isLoadSearch = false;
  },
  getAllSuccess(state, users) {
    state.listUsers = users;
    state.isLoadUsers = false;
  },
  getAllFailure(state, error) {
    state.errorsUsers = { error };
  },
  isLoadSearchStatus(state, isLoad) {
    state.isLoadSearch = isLoad;
  },
  filterSearchByCategories(state, categories) {
    state.isLoadSearch = true;
    if (categories.length) {
      state.filtredResultSearch = state.resultSearch.hotels.filter((hotel) =>
        categories.includes(hotel.static_data?.star_rating)
      );
    } else {
      state.filtredResultSearch = state.resultSearch.hotels;
    }

    state.isLoadSearch = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
