<template>
  <div>
    <footer class="footer" :class="this.footer == 'footer-dark' ? 'footer-dark' : ''">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 footer-copyright text-center">
            <p class="mb-0">Copyright 2024 © Trust Luxury - V{{ appVersion }}</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import json from '../data/menu';
import { mapGetters } from 'vuex';
import { version } from '../../package';

export default {
  name: 'footerpage',
  data() {
    return {
      appVersion: version,

      customizer: false,
      data: 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json)),
    };
  },
  computed: {
    ...mapGetters({
      footer: 'layout/footer',
    }),
  },
};
</script>
