import { mapGetters, mapState } from 'vuex';

const hotelsMixin = {
  data() {
    return {
      isActive: false,
      modalShow: false,
      quickViewProduct: [],
      counter: 1,
      priceArray: [],
      allfilters: [],
      items: [],
      filtered: false,
      filterQuery: '',
      allData: [],
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.product.products,
      productslist: (state) => state.product.productslist,
    }),
    ...mapGetters({
      tags: 'product/setTags',
      name: 'product/col2',
      filterPage: 'product/filterProducts',
    }),
  },
  methods: {
    disp() {
      this.isActive = !this.isActive;
    },
    addToCart: function (product, qty) {
      product.quantity = qty ? qty : 1;
      this.$store.dispatch('product/addToCart', product);
    },
    quickView: function (product) {
      return (this.quickViewProduct = product);
    },
    quickViewClose: function () {
      this.modalShow = false;
    },
    increment() {
      if (this.counter < this.quickViewProduct.stock) this.counter++;
    },
    decrement() {
      if (this.counter > 1) this.counter--;
    },
  },
};

export default hotelsMixin;
