import { usersService } from '../../_services';
import router from '../../router/index';
import Menu from '../../data/menu';
import MenuB2B from '../../data/menu.json';
import { UserRole } from '../../utils/auth.roles';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user: user.user } : { status: {}, user: null };

const state = { user: initialState ? initialState : {}, loginError: '' };

const actions = {
  async login({ dispatch, commit }, { username, password }) {
    commit('loginRequest', { username });
    return usersService.login({ email: username, password: password }).then(
      (user) => {
        let userData = user.data;
        commit('loginSuccess', userData);
        if (userData.access_token) {
          localStorage.setItem('user', JSON.stringify(userData));
        }
        if (userData.user.roles[0].name === 'admin') {
          localStorage.setItem('menu', JSON.stringify(Menu));
        } else {
          localStorage.setItem('menu', JSON.stringify(MenuB2B));
        }
        router.push({ name: 'dashboard' });
        return true;
      },
      (error) => {
        commit('loginFailure', error);
        return false;
      }
    );
  },

  logoutUser({ commit }) {
    usersService.logout();
    commit('logout');
    router.push({ name: 'LoginValidate' });
  },

  registerUser({ commit }, payload) {
    return usersService.registerUser(payload).then(
      (res) => {
        return res.data;
      },
      (error) => {
        return error;
      }
    );
  },

  setPassword({ commit }, payload) {
    return usersService.setPasswordToUser(payload).then(
      (res) => {
        return res.data;
      },
      (error) => {
        return error;
      }
    );
  },
};

const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.loginError = true;
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
};

const getters = {
  user: (state) => state.user,
  loginError: (state) => state.loginError,
  isAdmin: (state) => state.user.user.roles[0]?.name === UserRole.Admin,
  isAdminOrPrime: (state) =>
    state.user.user.roles[0].name === UserRole.Admin || state.user.user.roles[0]?.name === UserRole.Prime,
};

export const authentication = {
  namespaced: true,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
