<template>
  <div class="modal fade modal-bookmark" id="addUserModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header py-3">
          <h5 class="modal-title">{{ $t('button.addUser') }}</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="form-bookmark needs-validation">
            <!-- <div class="row mb-3">
              <div class="col">
                <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul class="txt-danger">
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                  </ul>
                </p>
              </div>
            </div> -->
            <div class="row form-group__title">
              <div class="col">
                <h5>{{ $t('user.contactDetails') }}</h5>
              </div>
              <div class="col media justify-content-end">
                <label
                  class="col-form-label m-r-10"
                  v-tooltip.top="'Utilisateur Prime : Réservations sans paiement possible'"
                  ><strong> Prime </strong>
                </label>
                <div class="text-end icon-state">
                  <label class="switch">
                    <input type="checkbox" v-model="primeUser" @click="isPrime = !isPrime" /><span
                      class="switch-state"
                      :class="{ 'bg-secondary': isPrime }"></span>
                  </label>
                </div>
              </div>
              <hr />
            </div>
            <div class="row mb-3">
              <div class="form-group col-md-4">
                <label>{{ $t('user.firstName') }}</label>
                <input
                  class="form-control"
                  v-model="user.first_name"
                  type="text"
                  required
                  :placeholder="$t('user.firstName')" />
              </div>
              <div class="form-group col-md-4">
                <label>{{ $t('user.lastName') }}</label>
                <input
                  class="form-control"
                  v-model="user.last_name"
                  type="text"
                  required
                  :placeholder="$t('user.lastName')" />
              </div>
              <div class="form-group col-md-4">
                <label>{{ $t('user.position') }}</label>
                <input
                  class="form-control"
                  v-model="user.position"
                  type="text"
                  required
                  :placeholder="$t('user.position')" />
              </div>
              <div class="form-group col-md-5">
                <label>{{ $t('user.email') }}</label>
                <input
                  data-testid="input-email"
                  id="input-email"
                  v-model="user.email"
                  class="form-control"
                  type="email"
                  required
                  :placeholder="$t('user.email')"
                  :class="{
                    'is-invalid': submitted && !email,
                  }" />
                <div v-show="submitted && !user.email" class="invalid-feedback">{{ $t('errors.RequiredEmail') }}</div>
              </div>
              <div class="form-group col-md-3">
                <label>{{ $t('user.phone') }}</label>
                <input
                  id="input-phone"
                  v-model="user.phone_number"
                  autocomplete=""
                  class="form-control"
                  type="text"
                  name="phoneNumber"
                  required=""
                  :placeholder="$t('user.phone')" />
              </div>
              <div class="form-group col-md-4">
                <label>Avatar</label>
                <input class="form-control" type="file" @change="handleFileUploadMedia($event, 'avatar')" />
              </div>
              <div class="form-group col-md-8 mb-0">
                <label class="form-label">{{ $t('user.about') }}</label>
                <textarea
                  class="form-control"
                  v-model="user.about"
                  rows="5"
                  placeholder="Enter About your description"></textarea>
              </div>
              <div class="form-group col-md-4">
                <div v-if="user.avatar" class="w-100 block-img">
                  <img :src="!avatarEdited ? baseURL + user.avatar : user.avatar" class="img-fluid" height="200" />
                </div>
              </div>
            </div>
            <div class="form-group__title">
              <h5>{{ $t('user.companyDetails') }}</h5>
              <hr />
            </div>
            <div class="row mb-3">
              <div class="form-group col-sm-6 col-md-4">
                <label class="form-label">{{ $t('user.socialReason') }}</label>
                <input
                  id="input-company"
                  v-model="user.legal_entity_details.social_reason"
                  class="form-control"
                  type="text"
                  name="company"
                  required
                  :placeholder="$t('user.socialReason')" />
              </div>
              <div class="form-group col-sm-6 col-md-4">
                <label class="form-label">{{ $t('user.taxpayerId') }}</label>
                <input
                  id="input-company"
                  v-model="user.legal_entity_details.taxpayer_id"
                  class="form-control"
                  type="text"
                  name="taxpayer"
                  required=""
                  :placeholder="$t('user.taxpayerId')" />
              </div>
              <div class="form-group col-sm-6 col-md-4">
                <label class="form-label">{{ $t('user.headquarterCity') }}</label>
                <input
                  id="input-city"
                  v-model="user.legal_entity_details.city_of_head_office"
                  class="form-control"
                  type="text"
                  name="city"
                  required
                  :placeholder="$t('user.headquarterCity')" />
              </div>

              <div class="form-group col-sm-6 col-md-3">
                <label class="form-label">{{ $t('user.legalEntityCity') }}</label>
                <input
                  id="input-company"
                  v-model="user.legal_entity_details.city_of_legal_entity"
                  class="form-control"
                  type="text"
                  name="taxpayer"
                  required=""
                  :placeholder="$t('user.legalEntityCity')" />
              </div>
              <div class="form-group col-sm-6 col-md-3">
                <label class="form-label">{{ $t('user.legalEntityCity') }}</label>
                <input
                  id="input-company"
                  v-model="user.legal_entity_details.legal_address"
                  class="form-control"
                  type="text"
                  name="legal_address"
                  required=""
                  placeholder="Legal address" />
              </div>
              <div class="form-group col-sm-6 col-md-3">
                <label class="form-label">{{ $t('user.legalEntityCity') }}</label>
                <input
                  id="input-city"
                  v-model="user.legal_entity_details.zip_code"
                  class="form-control"
                  type="text"
                  name="address_legal_postal_code"
                  required=""
                  placeholder="ZIP/Postal code" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="form-group col-md-6">
                <label>Logo</label>
                <input class="form-control" type="file" @change="handleFileUploadMedia($event, 'logo')" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div v-if="user.logo" class="w-100 block-img">
                  <img :src="!logoEdited ? baseURL + user.logo : user.logo" class="img-fluid" />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" @click="checkForm">Save</button>
          <button class="btn btn-primary ms-2" ref="Close" type="button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { baseURL } from '@/constants/config';
export default {
  name: 'AddUserModal',
  data() {
    return {
      isPrime: false,
      primeUser: '',
      user: {
        first_name: '',
        last_name: '',
        position: '',
        phone_number: '',
        about: '',
        website: '',
        email: '',
        legal_entity_details: {
          taxpayer_id: '',
          city_of_legal_entity: '',
          social_reason: '',
          legal_address: '',
          city_of_head_office: '',
          zip_code: '',
        },
        language: '',
        role_id: '',
      },
      errors: [],
      baseURL: baseURL,
      logoEdited: false,
      avatarEdited: false,
      roles: [
        { id: 1, name: 'Admin' },
        { id: 2, name: 'Partner Prime' },
      ],
    };
  },
  methods: {
    ...mapActions(['addUser']),
    resetModal() {
      this.user = {
        first_name: '',
        last_name: '',
        position: '',
        phone_number: '',
        about: '',
        website: '',
        email: '',
        legal_entity_details: {
          taxpayer_id: '',
          city_of_legal_entity: '',
          social_reason: '',
          legal_address: '',
          city_of_head_office: '',
          zip_code: '',
        },
        language: '',
        role_id: '',
      };
      this.errors = [];
    },
    handleFileUploadMedia(event, type) {
      const file = event.target.files[0];
      this.createBase64ImageMedia(file, type);
    },
    createBase64ImageMedia(fileObject, type) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = () => {
        if (type === 'logo') {
          this.user.logo = reader.result;
          this.logoEdited = true;
        } else if (type === 'avatar') {
          this.user.avatar = reader.result;
          this.avatarEdited = true;
        }
      };
    },
    checkForm: function (e) {
      if (this.primeUser) this.user.role_id = 2;

      if (this.user.first_name && this.user.last_name && this.user.email) {
        this.addUser(this.user).then((res) => {
          this.$refs.Close.click();
        });
        return true;
      }

      this.errors = [];

      if (!this.user.first_name || !this.user.last_name) {
        this.errors.push('Name required.');
      }
      if (!this.user.email) {
        this.errors.push('Email required.');
      }

      e.preventDefault();
    },
  },
};
</script>
<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
