<template>
  <div class="container-fluid bg-login">
    <LoginLogo />
    <div class="row">
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div class="login-main lg">
              <div class="login-main__language">
                <Language />
              </div>
              <b-card no-body class="p-5">
                <b-card-text>
                  <form v-if="!registerSuccessed" @keyup.enter="loginUser" class="theme-form">
                    <h1 class="f-w-700 txt-primary">{{ $t('wording.authTitle') }}</h1>
                    <h5 class="f-w-700">{{ $t('wording.createAccountTitle') }}</h5>
                    <p class="f-w-600">{{ $t('wording.createAccountSubTitle') }}</p>
                    <div v-if="registerStep === 1" class="first-step">
                      <div class="row form-group">
                        <div class="col-6 pe-1">
                          <input
                            class="form-control"
                            v-model="user.first_name"
                            type="text"
                            required
                            :placeholder="$t('user.firstName')" />
                        </div>
                        <div class="col-6 ps-1">
                          <input
                            class="form-control"
                            v-model="user.last_name"
                            type="text"
                            required
                            :placeholder="$t('user.lastName')" />
                        </div>
                      </div>
                      <div class="form-group">
                        <input
                          class="form-control"
                          v-model="user.position"
                          type="text"
                          required
                          :placeholder="$t('user.position')" />
                      </div>
                      <div class="form-group">
                        <input
                          data-testid="input-email"
                          id="input-email"
                          v-model="user.email"
                          class="form-control"
                          type="email"
                          required
                          :placeholder="$t('user.email')"
                          :class="{
                            'is-invalid': submitted && !email,
                          }" />
                        <div v-show="submitted && !user.email" class="invalid-feedback">
                          {{ $t('errors.RequiredEmail') }}
                        </div>
                      </div>
                      <div class="form-group">
                        <input
                          id="input-phone"
                          v-model="user.phone_number"
                          autocomplete=""
                          class="form-control"
                          type="text"
                          name="phoneNumber"
                          required=""
                          :placeholder="$t('user.phone')" />
                      </div>
                      <p class="mt-4 mb-0 text-center">
                        <a
                          href="javascript:void(0)"
                          class="btn btn-primary btn-block w-100 login-btn btn-pill"
                          @click="nextStep()">
                          {{ $t('button.next') }}
                        </a>
                      </p>
                    </div>
                    <div v-if="registerStep === 2" class="second-step">
                      <div class="row form-group">
                        <div class="col-6 pe-1">
                          <input
                            id="input-company"
                            v-model="user.legal_entity_details.social_reason"
                            class="form-control"
                            type="text"
                            name="company"
                            required
                            :placeholder="$t('user.socialReason')" />
                        </div>
                        <div class="col-6 ps-1">
                          <input
                            id="input-city"
                            v-model="user.legal_entity_details.city_of_head_office"
                            class="form-control"
                            type="text"
                            name="city"
                            required
                            :placeholder="$t('user.headquarterCity')" />
                        </div>
                      </div>
                      <div class="form-group">
                        <input
                          id="input-company"
                          v-model="user.legal_entity_details.taxpayer_id"
                          class="form-control"
                          type="text"
                          name="taxpayer"
                          required=""
                          :placeholder="$t('user.taxpayerId')" />
                      </div>
                      <div class="form-group">
                        <input
                          id="input-company"
                          v-model="user.legal_entity_details.city_of_legal_entity"
                          class="form-control"
                          type="text"
                          name="taxpayer"
                          required=""
                          :placeholder="$t('user.legalEntityCity')" />
                      </div>
                      <div class="row form-group">
                        <div class="col-6 pe-1">
                          <input
                            id="input-company"
                            v-model="user.legal_entity_details.legal_address"
                            class="form-control"
                            type="text"
                            name="legal_address"
                            required=""
                            placeholder="Legal address" />
                        </div>
                        <div class="col-6 ps-1">
                          <input
                            id="input-city"
                            v-model="user.legal_entity_details.zip_code"
                            class="form-control"
                            type="text"
                            name="address_legal_postal_code"
                            required=""
                            placeholder="ZIP/Postal code" />
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="checkbox p-0">
                          <input id="checkbox1" type="checkbox" />
                          <label class="text-muted" for="checkbox1">Agree with <a href="#">Privacy Policy</a></label>
                        </div>
                        <div class="row">
                          <div class="col">
                            <button
                              class="btn btn-primary btn-block w-100 login-btn btn-pill"
                              @click="registerStep = 1">
                              {{ $t('button.previous') }}
                            </button>
                          </div>
                          <div class="col">
                            <button
                              id="btn-register"
                              class="btn btn-primary btn-block w-100 login-btn btn-pill"
                              type="button"
                              :disabled="submitted"
                              @click="registerNewUser">
                              {{ $t('button.register') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="mt-4 mb-0 text-center">
                      Already have an account?
                      <router-link class="ms-2" :to="{ name: 'LoginValidate' }"> {{ $t('button.login') }} </router-link>
                    </p>
                  </form>
                  <div v-else>
                    <h1 class="f-w-700 txt-primary">{{ $t('wording.authTitle') }}</h1>
                    <h5 class="f-w-700 lh-base my-3">{{ $t('authentication.RegisterSuccessTitle') }}</h5>
                    <p class="f-w-600">{{ $t('authentication.RegisterSuccess') }}</p>
                    <router-link
                      class="btn btn-primary btn-block w-100 login-btn btn-pill mt-3"
                      :to="{ name: 'LoginValidate' }">
                      Login
                    </router-link>
                  </div>
                </b-card-text>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
//import Userauth from "../../auth/js/index";
import { mapActions, mapGetters } from 'vuex';
import Language from '@/components/header/language';
import LoginLogo from '@/components/header/LoginLogo.vue';

export default {
  name: 'register',
  components: {
    Language,
    LoginLogo,
  },
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        position: '',
        phone_number: '',
        about: '',
        website: '',
        email: '',
        legal_entity_details: {
          taxpayer_id: '',
          city_of_legal_entity: '',
          social_reason: '',
          legal_address: '',
          city_of_head_office: '',
          zip_code: '',
        },
        language: '',
      },
      submitted: false,
      registerSuccessed: false,
      registerStep: 1,
    };
  },
  computed: {
    ...mapGetters(['loginError']),
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
  },
  methods: {
    ...mapActions(['registerUser']),
    nextStep() {
      if (!this.user.email || !this.user.first_name || !this.user.first_name) {
        this.$toast.add({
          severity: 'error',
          summary: this.$i18n.t('errors.AllRequired'),
          life: 4000,
        });

        this.submitted = false;
        return;
      } else {
        this.registerStep = 2;
      }
    },
    registerNewUser() {
      this.submitted = true;
      this.registerUser(this.user).then((res) => {
        if (res.status === 422) {
          this.$toast.add({
            severity: 'error',
            summary: this.$i18n.t('errors.RegisterFailedEmailTaken'),
            life: 4000,
          });
        } else if (res.status >= 400 && res.status < 500) {
          this.$toast.add({
            severity: 'error',
            summary: this.$i18n.t('errors.RegisterFailedDefaultError'),
            life: 4000,
          });
        } else {
          this.registerSuccessed = true;
        }
        this.submitted = false;
      });
    },
  },
};
</script>
<style scoped>
.container-fluid {
  max-height: 100vh;
  overflow: hidden;
}
</style>
