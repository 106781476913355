export const category = [
  /*   {
    name: 'hotel_name',
    values: [
      {
        applied: false,
      },
    ],
  },
  {
    name: 'min_rating',
    values: [
      {
        hotels_cnt: 46,
        applied: false,
        val: 5,
      },
      {
        hotels_cnt: 46,
        applied: false,
        val: 6,
      },
      {
        hotels_cnt: 36,
        applied: false,
        val: 7,
      },
      {
        hotels_cnt: 23,
        applied: false,
        val: 8,
      },
      {
        hotels_cnt: 15,
        applied: false,
        val: 9,
      },
    ],
  }, */
  {
    name: 'Stars',
    values: [
      {
        hotels_cnt: 13,
        applied: false,
        val: 5,
      },
      {
        hotels_cnt: 24,
        applied: false,
        val: 4,
      },
      {
        hotels_cnt: 14,
        applied: false,
        val: 3,
      },
      {
        hotels_cnt: 3,
        applied: false,
        val: 2,
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 1,
      },
      {
        hotels_cnt: 17,
        applied: false,
        val: 0,
      },
    ],
  },
  /*  {
    name: 'chain_ids',
    values: [
      {
        hotels_cnt: 1,
        applied: false,
        val: 48,
      },
      {
        hotels_cnt: 1,
        applied: false,
        val: 58,
      },
      {
        hotels_cnt: 1,
        applied: false,
        val: 126,
      },
      {
        hotels_cnt: 1,
        applied: false,
        val: 641,
      },
      {
        hotels_cnt: 1,
        applied: false,
        val: 748,
      },
      {
        hotels_cnt: 4,
        applied: false,
        val: 807,
      },
      {
        hotels_cnt: 1,
        applied: false,
        val: 962,
      },
      {
        hotels_cnt: 2,
        applied: false,
        val: 1066,
      },
      {
        hotels_cnt: 1,
        applied: false,
        val: 1158,
      },
      {
        hotels_cnt: 1,
        applied: false,
        val: 1219,
      },
      {
        hotels_cnt: 1,
        applied: false,
        val: 1319,
      },
    ],
  },
  {
    name: 'kinds',
    values: [
      {
        hotels_cnt: 44,
        applied: false,
        val: 'hotel',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'hostel',
      },
      {
        hotels_cnt: 5,
        applied: false,
        val: 'apartment',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'apart_hotel',
      },
      {
        hotels_cnt: 5,
        applied: false,
        val: 'apart',
      },
      {
        hotels_cnt: 4,
        applied: false,
        val: 'guesthouse',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'cottage_villa_bungalow',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'camping',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'glamping',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'sanatorium',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'boutique',
      },
      {
        hotels_cnt: 18,
        applied: false,
        val: 'resort',
      },
    ],
  },
  {
    name: 'point_distance',
    values: [
      {
        applied: false,
      },
    ],
  },
  {
    name: 'bedrooms',
    values: [
      {
        hotels_cnt: 70,
        applied: false,
        val: 1,
      },
      {
        hotels_cnt: 3,
        applied: false,
        val: 2,
      },
      {
        hotels_cnt: 1,
        applied: false,
        val: 3,
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 4,
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 5,
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 6,
      },
    ],
  },
  {
    name: 'price',
    values: [
      {
        applied: false,
      },
    ],
  },
  {
    name: 'price_per_night',
    values: [
      {
        applied: false,
      },
    ],
  },
  {
    name: 'amenities',
    values: [
      {
        hotels_cnt: 57,
        applied: false,
        val: 'air-conditioning',
      },
      {
        hotels_cnt: 8,
        applied: false,
        val: 'balcony',
      },
      {
        hotels_cnt: 50,
        applied: false,
        val: 'beach',
      },
      {
        hotels_cnt: 7,
        applied: false,
        val: 'kitchen',
      },
      {
        hotels_cnt: 71,
        applied: false,
        val: 'private-bathroom',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'spa-access',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'window',
      },
      {
        hotels_cnt: 29,
        applied: false,
        val: 'with-view',
      },
      {
        hotels_cnt: 31,
        applied: false,
        val: 'has_airport_transfer',
      },
      {
        hotels_cnt: 42,
        applied: false,
        val: 'has_busyness',
      },
      {
        hotels_cnt: 44,
        applied: false,
        val: 'has_disabled_support',
      },
      {
        hotels_cnt: 38,
        applied: false,
        val: 'has_fitness',
      },
      {
        hotels_cnt: 67,
        applied: false,
        val: 'has_internet',
      },
      {
        hotels_cnt: 51,
        applied: false,
        val: 'has_kids',
      },
      {
        hotels_cnt: 59,
        applied: false,
        val: 'has_parking',
      },
      {
        hotels_cnt: 7,
        applied: false,
        val: 'has_pets',
      },
      {
        hotels_cnt: 54,
        applied: false,
        val: 'has_pool',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'has_ski',
      },
      {
        hotels_cnt: 30,
        applied: false,
        val: 'has_smoking',
      },
      {
        hotels_cnt: 54,
        applied: false,
        val: 'has_spa',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'has_anticovid',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'private-room',
      },
      {
        hotels_cnt: 60,
        applied: false,
        val: 'has_meal',
      },
      {
        hotels_cnt: 10,
        applied: false,
        val: 'has_jacuzzi',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'has_ecar_charger',
      },
    ],
  },
  {
    name: 'free_cancellation',
    values: [
      {
        hotels_cnt: 67,
        applied: false,
        val: true,
      },
    ],
  },
  {
    name: 'payment_types',
    values: [
      {
        hotels_cnt: 71,
        applied: false,
        val: 'now',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'hotel',
      },
    ],
  },
  {
    name: 'nocc_only',
    values: [
      {
        hotels_cnt: 67,
        applied: false,
        val: true,
      },
    ],
  },
  {
    name: 'no_deposit',
    values: [
      {
        hotels_cnt: 71,
        applied: false,
        val: true,
      },
    ],
  },
  {
    name: 'beddings',
    values: [
      {
        hotels_cnt: 20,
        applied: false,
        val: 'single',
      },
      {
        hotels_cnt: 65,
        applied: false,
        val: 'double',
      },
    ],
  },
  {
    name: 'meals',
    values: [
      {
        hotels_cnt: 26,
        applied: false,
        val: 'no_meal',
      },
      {
        hotels_cnt: 64,
        applied: false,
        val: 'breakfast',
      },
      {
        hotels_cnt: 36,
        applied: false,
        val: 'all_inclusive',
      },
      {
        hotels_cnt: 45,
        applied: false,
        val: 'half_board',
      },
      {
        hotels_cnt: 20,
        applied: false,
        val: 'full_board',
      },
    ],
  },
  {
    name: 'spend_loyalty',
    values: [
      {
        hotels_cnt: 71,
        applied: false,
        val: 'ratehawk_points_eur',
      },
    ],
  },
  {
    name: 'vat',
    values: [
      {
        hotels_cnt: 71,
        applied: false,
        val: 'excluded',
      },
      {
        hotels_cnt: 0,
        applied: false,
        val: 'included',
      },
    ],
  }, */
];
