<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="product-page-details">
          <h5 class="my-0">{{ $t('wording.AmenitiesThere') }}</h5>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div v-for="(equipement, index) in equipementData" :key="index" class="col-md-3 mb-3">
            <div>
              <span :class="`size-20 me-2 icon-${replaceSpecialCharacters(equipement.group_name)}`"></span>
              <strong class="mb-2">{{ equipement.group_name }}</strong>
              <ul class="ps-3">
                <li v-for="(amenitie, index) in equipement.amenities" :key="index">- {{ amenitie }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const _ = require('lodash');
export default {
  props: ['equipementData'],
  components: {},
  name: 'HotelEquipmentCard',
  methods: {
    replaceSpecialCharacters(icon) {
      return _.kebabCase(icon);
    },
  },
};
</script>
