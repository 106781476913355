<template>
  <div class="container-fluid fixed-middle">
    <div class="row">
      <div class="col">
        <div class="card py-2 px-2 px-sm-5 mw-720">
          <div class="card-body">
            <div class="order">
              <div class="order__title text-center">
                <h2>{{ $t('booking.BookingFailed') }}</h2>
                <p>{{ $t('booking.BookingFailedTitle') }}</p>
                <h5 v-if="errorMsg" class="mb-0">{{ $t(`errors.${errorMsg}`) }}</h5>
              </div>
              <div class="order__info text-center">
                <p>{{ $t('booking.BookingFailedMessage') }}</p>
              </div>
              <div class="order__action d-flex justify-content-center gap-3">
                <router-link :to="{ name: 'makeReservation', params: { bookHash } }" class="btn btn-primary mx-2">
                  {{ $t('button.backToOrder') }}
                </router-link>
                <router-link to="/" class="btn btn-secondary mx-2">
                  {{ $t('button.goToHome') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bookHash: '',
      errorMsg: '',
    };
  },
  created() {
    this.bookHash = this.$route.query.book_hash;
    this.errorMsg = this.$route.query.error;
  },
};
</script>

<style lang="scss">
.fixed-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  .order {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
}
.mw-720 {
  max-width: 720px;
}
</style>
