<template>
  <div class="col-md-4 box-col-6">
    <SearchSidebar />
    <div class="card">
      <div class="card-body">
        <div class="collection-filter-block">
          <ul class="pro-services">
            <li>
              <div class="media">
                <vue-feather type="map-pin"></vue-feather>
                <div class="media-body">
                  <h5>{{ hotel_data.address }} - {{ hotel_data.postal_code }}</h5>
                  <p v-if="hotel_data.center_distance">
                    {{ (hotel_data.center_distance / 1000).toFixed(1) }} {{ $t('wording.fromCityCenter') }}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <vue-feather type="clock"></vue-feather>
                <div class="media-body">
                  <div class="row">
                    <div class="col-6"><h5>Check In</h5></div>
                    <div class="col-6"><h5>Check Out</h5></div>
                    <div class="col-6">{{ hotel_data.check_in_time }}</div>
                    <div class="col-6">{{ hotel_data.check_out_time }}</div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <vue-feather type="credit-card"></vue-feather>
                <div class="media-body">
                  <h5>{{ $t('paymentTypes.title') }}</h5>
                  <p>
                    <span v-for="(item, index) in hotel_data.payment_methods" :key="index">
                      {{ $t(`paymentTypes.${item}`) }} &nbsp;
                    </span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SearchSidebar from '@/components/search/searchSidebar.vue';
export default {
  props: ['hotel_data'],
  components: {
    SearchSidebar,
  },
};
</script>
