<template>
  <div class="container-fluid bg-login">
    <LoginLogo />
    <div class="row">
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div class="login-main">
              <div class="login-main__language">
                <Language />
              </div>
              <b-card no-body class="p-5">
                <b-card-text>
                  <form @keyup.enter="loginUser" class="theme-form">
                    <h1 class="f-w-700 txt-primary">{{ $t('wording.authTitle') }}</h1>
                    <h5 class="f-w-700">{{ $t('authentication.loginTitle') }}</h5>
                    <p class="f-w-600">{{ $t('authentication.loginText') }}</p>
                    <div class="form-group">
                      <input
                        data-testid="input-username"
                        id="input-username"
                        v-model="username"
                        class="form-control"
                        type="text"
                        required=""
                        placeholder="Username"
                        :class="{
                          'is-invalid': submitted && !username,
                        }" />
                      <div v-show="submitted && !username" class="invalid-feedback">
                        {{ $t('errors.RequiredEmail') }}
                      </div>
                    </div>
                    <div class="form-group">
                      <input
                        id="input-password"
                        v-model="password"
                        autocomplete=""
                        class="form-control"
                        :type="type"
                        name="login[password]"
                        required=""
                        placeholder="Password"
                        :class="{
                          'is-invalid': submitted && !username,
                        }" />
                      <div v-show="submitted && !password" class="invalid-feedback">
                        {{ $t('errors.RequiredPassword') }}
                      </div>
                      <div class="show-hide" @click="showPassword">
                        <span class="show"></span>
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label class="text-muted" for="checkbox1">{{ $t('authentication.rememberPassword') }}</label>
                      </div>
                      <button
                        id="btn-sign-in"
                        class="btn btn-primary btn-block w-100 login-btn btn-pill"
                        type="button"
                        :disabled="submitted"
                        @click="loginUser">
                        {{ $t('button.login') }}
                      </button>
                    </div>
                    <p class="mt-4 mb-0 text-center">
                      {{ $t('authentication.dontHaveAccount') }}
                      <router-link class="ms-2" :to="{ name: 'RegisterB2B' }">
                        {{ $t('button.createAccount') }}
                      </router-link>
                    </p>
                  </form>
                </b-card-text>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
//import Userauth from "../../auth/js/index";
import { mapActions, mapGetters } from 'vuex';
import Language from '@/components/header/language';
import LoginLogo from '@/components/header/LoginLogo.vue';

export default {
  name: 'login',
  components: {
    Language,
    LoginLogo,
  },
  data() {
    return {
      type: 'password',
      email: '',
      password: '',
      username: '',
      passwordjwt: '',
      submitted: false,
    };
  },
  computed: {
    ...mapGetters(['loginError']),
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
  },
  methods: {
    ...mapActions(['login']),
    showPassword: function () {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    loginUser() {
      this.submitted = true;
      if (!this.username || !this.password) {
        this.$toast.add({
          severity: 'error',
          summary: this.$i18n.t('errors.AllRequired'),
          life: 4000,
        });

        this.submitted = false;
        return;
      }
      const userInfo = {
        username: this.username,
        password: this.password,
      };
      this.login(userInfo).then((res) => {
        if (!res) {
          this.$toast.add({
            severity: 'error',
            summary: this.$i18n.t('errors.LoginFailed'),
            life: 4000,
          });
        }
        this.submitted = false;
      });
    },
  },
};
</script>
<style scoped>
.container-fluid {
  max-height: 100vh;
  overflow: hidden;
}
</style>
