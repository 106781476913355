<template>
  <Breadcrumbs :main="'Réservation'" title="Détails" />

  <div class="container-fluid">
    <div v-if="!isLoading" class="row">
      <div class="col-md-8 order-md-1 order-2">
        <RoomsCard
          :bookDataGuests="bookData.guests"
          :bookDetailsRooms="bookDetails.rooms"
          :roomName="bookData.room_data?.name" />
        <ContactCard :userContact="userContact" />
        <div class="card">
          <div class="card-body text-center">
            <button :disabled="isLoadingPayment" class="btn btn-primary" @click="createBooking">
              {{ $t('booking.paiementBtn') }}
            </button>
            <button
              v-if="isAdminOrPrime"
              :disabled="isLoadingPayment"
              class="btn btn-secondary ms-md-2 mt-2 mt-lg-0"
              @click="createBookingPrime">
              {{ $t('paymentTypes.bankTransfer') }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4 order-1">
        <div class="card">
          <div class="card-header">
            <span v-if="hotel_data?.star_rating" class="rating">
              <i v-for="(item, index) in hotel_data?.star_rating" :key="index" class="fa fa-star"></i>
            </span>
            <h4>{{ hotel_data?.name }}</h4>
            <p>{{ hotel_data?.address }}</p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                {{ $t('orders.checkIn') }} <br />
                {{ bookData.checkin }} <br />
                {{ $t('orders.from') }} {{ hotel_data.check_in_time }}
              </div>
              <div class="col-6">
                {{ $t('orders.checkOut') }} <br />
                {{ bookData.checkout }} <br />
                {{ $t('orders.to') }} {{ hotel_data.check_out_time }}
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                {{ $t(`meals.${bookData.room_data.meal}`) }}
              </div>
              <div class="col-12">
                <span v-if="cancellationRoom" class="text-success f-w-700">
                  {{ $t('orders.FreeCancellationBefore') }}
                  {{ $d(cancellationRoom, 'long') }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h4>{{ $t('wording.rooms') }}</h4>
          </div>
          <div class="card-body">
            <div class="row" v-for="(room, indexRoom) in bookData.guests" :key="indexRoom">
              <div class="col">
                {{ bookData.room_data?.name }} {{ $t('wording.for') }} {{ room.adults }} {{ $t('wording.adults') }}
                <span v-if="room.children.length">
                  {{ $t('wording.and') }} {{ room.children.length }} {{ $t('wording.childrens') }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6">
                <h4>{{ $t('wording.totalPrice') }}</h4>
              </div>
              <div class="col-6 d-flex">
                <h4>
                  {{ bookData.room_data.payment_options?.payment_types[0]?.show_amount }}
                  {{ bookData.room_data.payment_options?.payment_types[0]?.show_currency_code }}
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p class="mb-0">
                  {{ $t('orders.PriceOf') }} {{ bookData.guests.length }} {{ $t('wording.rooms') }}
                  {{ $t('wording.for') }} {{ numberOfNights }} {{ $t('wording.nights') }} <br />
                  {{ $t('orders.VATnotApplicable') }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="bookData.room_data.payment_options?.payment_types[0]?.tax_data.length" class="card-body">
            <p class="text-danger">{{ $t('wording.city_tax_description') }}</p>
            <div class="row">
              <div class="col-6">{{ $t('wording.city_tax') }}</div>
              <div class="col-6 d-flex">
                {{ bookData.room_data.payment_options?.payment_types[0]?.tax_data.taxes[0]?.amount }}
                {{ bookData.room_data.payment_options?.payment_types[0]?.tax_data.taxes[0]?.currency_code }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import checkoutPage from '@/mixins/checkoutPage';
import ContactCard from './ContactCard.vue';
import RoomsCard from './RoomsCard.vue';
import { supplierData } from '@/constants/config';

export default {
  mixins: [checkoutPage],
  components: {
    ContactCard,
    RoomsCard,
  },
  data() {
    return {
      bookHash: '',
      isLoadingPayment: false,
      isLoading: false,
      bookData: {},
      hotel_data: {},
      room_data: {},
      activeAmount: '0',
      totalAmount: 0,
      numberOfNights: 0,
      bookDetails: {
        user: {},
        supplier_data: {},
        partner: {},
        language: this.$i18n.locale,
        rooms: [],
        upsell_data: [],
        payment_type: {},
      },
      paymentTypes: {},
      userContact: {
        first_name: { value: '', errormsg: '' },
        last_name: { value: '', errormsg: '' },
        phone: { value: '', errormsg: '' },
        email: { value: '', errormsg: '' },
        address: { value: '', errormsg: '' },
        country: { value: '', errormsg: '' },
        city: { value: '', errormsg: '' },
        state: { value: '', errormsg: '' },
        pincode: { value: '', errormsg: '' },
      },
      submitted: false,
      payment: false,
      complete: false,
      urlIframeStripe: '',
    };
  },
  computed: {
    ...mapGetters({
      selectedHotel: 'orders/selectedHotel',
      selectedRoomHotel: 'orders/selectedRoomHotel',
      isAdminOrPrime: 'isAdminOrPrime',
    }),
    cancellationRoom() {
      return this.bookData.room_data?.payment_options.payment_types[0].cancellation_penalties.free_cancellation_before;
    },
  },
  methods: {
    ...mapActions({
      getOrderTmpByHash: 'orders/getOrderTmpByHash',
      orderFormPost: 'orders/orderFormPost',
      storeBooking: 'booking/createBooking',
      orderBookingFinish: 'orders/orderBookingFinish',
      createCheckoutSession: 'orders/createCheckoutSession',
      storeOrderTmpByHash: 'orders/storeOrderTmpByHash',
      prebookBooking: 'orders/prebookBooking',
    }),
    bookingFormGenerate() {
      const orderFormData = {
        book_hash: this.bookData.room_data.book_hash,
        language: this.$i18n.locale,
      };
      this.orderFormPost(orderFormData).then((res) => {
        this.bookDetails.partner = {
          partner_order_id: res?.partner_order_id,
          comment: 'partner_comment',
          amount_sell_b2b: 0,
        };
      });
    },
    goToPaymentPage() {
      window.location = this.urlIframeStripe;
    },
    formatFullBookData() {
      this.bookDetails.supplier_data = supplierData;
      this.bookDetails.user = {
        email: this.userContact.email.value,
        phone: this.userContact.phone.value,
      };
      this.bookData.client = {
        first_name: this.userContact.first_name.value,
        last_name: this.userContact.last_name.value,
        email: this.userContact.email.value,
        phone: this.userContact.phone.value,
      };
      const formattedBookData = {
        ...this.bookData,
        id: this.$route.params.id,
        order_details: this.bookDetails,
      };

      return formattedBookData;
    },
    createBooking() {
      const hasErrorForm = this.onSubmit();
      if (hasErrorForm) {
        this.$toast.add({
          severity: 'error',
          summary: this.$i18n.t('errors.AllRequired'),
          life: 4000,
        });
      } else {
        this.isLoadingPayment = true;
        this.totalAmount = this.bookData.room_data.payment_options?.payment_types[0]?.show_amount;
        let params = {
          hotel_name: this.hotel_data?.name,
          amount: Number(this.totalAmount.replace('.', '')),
          book_hash: this.bookHash,
        };
        this.createCheckoutSession(params).then((res) => {
          let fullBookData = this.formatFullBookData();
          fullBookData.session_id = res.id;
          this.urlIframeStripe = res.url;
          this.storeOrderTmpByHash({ book_hash: this.bookHash, order_information: fullBookData }).then(() => {
            this.goToPaymentPage();
          });
          this.isLoadingPayment = false;
        });
      }
    },
    createBookingPrime() {
      const hasErrorForm = this.onSubmit();

      if (hasErrorForm) {
        this.$toast.add({
          severity: 'error',
          summary: this.$i18n.t('errors.AllRequired'),
          life: 4000,
        });
      } else {
        this.isLoadingPayment = true;
        this.totalAmount = this.bookData.room_data.payment_options?.payment_types[0]?.show_amount;
        let fullBookData = this.formatFullBookData();
        this.storeOrderTmpByHash({ book_hash: this.bookHash, order_information: fullBookData }).then(() => {
          this.$router.push({ name: 'BookingSuccess', query: { book_hash: this.bookHash, wire_transfer: true } });
        });
        this.isLoadingPayment = false;
      }
    },
    prebookCheck() {
      this.prebookBooking({
        hash: this.bookHash,
        price_increase_percent: 8,
      }).then((res) => {
        if (res.data.changes.price_changed) {
          this.bookData.room_data = res.data.hotels[0]?.rates[0];
          this.bookHash = res.data.hotels[0]?.rates[0]?.book_hash;
        }
      });
    },
  },
  created() {
    this.bookHash = this.$route.params.bookHash;
    const searchData = this.$route.query;
    this.isLoading = true;

    this.getOrderTmpByHash(this.bookHash).then((order) => {
      this.prebookCheck();
      if (!order) {
        this.$router.push({ name: 'hotelInfos', params: { hotelId: searchData.id }, query: searchData });
      }
      this.hotel_data = order.hotel_data;
      this.bookData = order;

      const guestsRooms = this.bookData.guests;
      const formatGuestsRooms = guestsRooms.map((room) => {
        let guests = [];
        for (let index = 0; index < room.adults; index++) {
          guests.push({
            first_name: '',
            last_name: '',
          });
        }
        for (let indexChild = 0; indexChild < room.children.length; indexChild++) {
          guests.push({
            first_name: '',
            last_name: '',
            age: room.children[indexChild],
            is_child: true,
          });
        }
        return {
          guests,
        };
      });
      this.bookDetails.rooms = formatGuestsRooms;
      this.bookDetails.payment_type = {
        amount: order.room_data.payment_options?.payment_types[0]?.show_amount,
        currency_code: order.room_data.payment_options?.payment_types[0]?.currency_code,
        type: 'deposit',
      };

      const date1 = new Date(order.checkin);
      const date2 = new Date(order.checkout);
      const timeDiff = Math.abs(date2.getTime() - date1.getTime());
      this.numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
      this.isLoading = false;
      this.bookingFormGenerate();
    });
  },
};
</script>
