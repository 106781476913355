<template>
  <div class="card" v-for="(hotel, index) in filtredResultSearch" :key="index">
    <div class="product-box">
      <div class="product-img position-relative">
        <div class="ribbon ribbon-danger" v-if="hotel.sale">Sale</div>
        <div class="ribbon ribbon-success ribbon-right" v-if="hotel.off">50%</div>
        <img
          class="img-fluid w-100 h-100"
          :src="
            hotel.static_data
              ? fixSizeImage(hotel.static_data.images[0])
              : getImageUrl('logo/bg-white-trust-luxury.jpg')
          "
          alt />
        <div class="product-hover">
          <ul>
            <router-link
              :to="{
                name: 'hotelInfos',
                params: { hotelId: hotel.id },
                query: searchDataFormatted,
              }">
              <li @click="addToCart(hotel)">
                <i class="icon-themify-search"> </i>
              </li>
            </router-link>
          </ul>
        </div>
      </div>
      <div class="product-details">
        <div class="rating">
          <i v-for="star of hotel.static_data?.star_rating" :key="star" class="fa fa-star"></i>
        </div>
        <router-link
          :to="{
            name: 'hotelInfos',
            params: { hotelId: hotel.id },
            query: searchDataFormatted,
          }">
          <h4>
            {{ hotel.static_data ? hotel.static_data.name : title(hotel.id) }}
          </h4>
        </router-link>
        <p v-if="hotel.static_data"><i class="fa fa-map-marker"></i> {{ hotel.static_data.address }}</p>
        <span
          v-for="(icon, indexIcon) in hotel.static_data?.serp_filters.slice(0, 5)"
          :key="indexIcon"
          :class="`size-20 me-2 icon-${icon}`"></span>
        <div v-if="hotel.rates[0]" class="product-price">
          {{ hotel.rates[0].daily_prices[0] }} € / Night
          <i
            v-if="hotel.rates[0].payment_options.payment_types[0].tax_data?.taxes"
            class="fa fa-info-circle"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="hasTax(hotel.rates[0].payment_options.payment_types[0].tax_data?.taxes)"></i>
          <del v-if="hotel.discountPrice">${{ hotel.discountPrice }}</del>
        </div>
        <p>
          {{ hotel.rates[0].room_name }} -
          {{ $t(`meals.${hotel.rates[0].meal}`) }}
        </p>
        <p v-if="!hotel.rates[0].payment_options.payment_types[0]?.cancellation_penalties.free_cancellation_before">
          Sans annulation gratuite
        </p>
        <p v-else>
          {{ $t('orders.FreeCancellationBefore') }}
          {{
            $d(hotel.rates[0].payment_options.payment_types[0]?.cancellation_penalties.free_cancellation_before, 'long')
          }}
        </p>
        <router-link
          class="btn btn-primary ms-auto"
          :to="{
            name: 'hotelInfos',
            params: { hotelId: hotel.id },
            query: searchDataFormatted,
          }"
          @click="$store.commit('orders/setHotel', hotel)">
          {{ $t('button.showAllRooms') }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import hotelsMixin from '@/mixins/hotelsMixin';
import { mapGetters } from 'vuex';
import getImage from '@/mixins/getImage';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.bundle';
export default {
  mixins: [hotelsMixin, getImage],
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  computed: {
    ...mapGetters(['searchData', 'filtredResultSearch']),
    searchDataFormatted() {
      return {
        ...this.searchData,
        guests: JSON.stringify(this.searchData.guests),
      };
    },
  },
  methods: {
    fixSizeImage(image) {
      if (image) {
        return image.replace('{size}', '640x400');
      }
      return this.getImageUrl('logo/bg-white-trust-luxury.jpg');
    },
    hasTax(taxes) {
      let taxtText =
        this.$t(`wording.${taxes ? taxes[0]?.name : ''}`) + ' : ' + taxes[0]?.amount + ' ' + taxes[0]?.currency_code;
      return taxtText;
    },
    title(title) {
      return title
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
  },
};
</script>
