<template>
  <div class="table-responsive add-project">
    <table class="table card-table table-vcenter text-nowrap">
      <thead>
        <tr>
          <th v-if="isAdmin">Partner</th>
          <th>N° Rés</th>
          <th>Hôtel</th>
          <th>Client</th>
          <th>{{ $t('orders.checkIn') }}</th>
          <th>{{ $t('orders.checkOut') }}</th>
          <th>Status</th>
          <th>Price</th>
          <th>Date rés</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in bookingList" :key="index">
          <td v-if="isAdmin">{{ item.user.name }}</td>
          <td>{{ item.num_reservation }}</td>
          <td>{{ item.hotel_name }}</td>
          <td>{{ getClientName(item.order_details.client) }}</td>
          <td>{{ $d(item.checkin, 'long') }}</td>
          <td>{{ $d(item.checkout, 'long') }}</td>
          <td>
            <span class="badge rounded-pill" :class="getClassStatus(item.status)"
              >{{ $t(`booking.status.${item.status}`) }}
              <span
                v-if="this.selectedBooking.id == item.id && loadingUpdateStatus"
                class="loader-box ps-1 d-inline-flex"
                ><span class="loader-15"></span
              ></span>
            </span>
          </td>
          <td>{{ item.amount }} {{ item.currency }}</td>
          <td>{{ $d(item.created_at, 'longTime') }}</td>
          <td class="text-end">
            <SpeedDial
              :model="getItemsMenu(item)"
              :radius="100"
              @click="selectedBooking = item"
              direction="left"
              buttonClass="btn-secondary btn-pill small-btn"
              :tooltipOptions="{ event: 'hover', position: 'top' }"
              :style="{ right: '10px', bottom: '5px' }" />
            <Toast />

            <!-- <a class="icon" href="javascript:void(0)"></a
                ><a class="btn btn-primary btn-sm px-2" href="javascript:void(0)"><i class="fa fa-eye"></i></a
                ><a class="icon ms-2" href="javascript:void(0)"></a
                ><a class="btn btn-danger btn-sm px-2" href="javascript:void(0)"><i class="fa fa-trash"></i></a> -->
          </td>
        </tr>
      </tbody>
    </table>

    <ConfirmDialog></ConfirmDialog>
  </div>
</template>
<script>
import ConfirmDialog from 'primevue/confirmdialog';
import bookingMixin from '@/mixins/bookingMixin';
import { mapActions, mapGetters } from 'vuex';
import { baseURL } from '@/constants/config';
export default {
  mixins: [bookingMixin],
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      showCancelModal: false,
      selectedBooking: '',
      first: 0,
      totalRecords: 0,
      loadingUpdateStatus: false,
    };
  },
  computed: {
    ...mapGetters({ bookingList: 'booking/bookingList', isAdmin: 'isAdmin' }),
  },
  methods: {
    ...mapActions({
      updateStatusBooking: 'booking/updateStatusBookingById',
      sendBookingVoucher: 'booking/sendBookingVoucher',
    }),
    getClientName(clientData) {
      if (clientData && clientData.first_name) {
        return clientData?.first_name + ' ' + clientData?.last_name;
      }
      return '';
    },
    sendVoucher() {
      this.sendBookingVoucher(this.selectedBooking.id).then((res) => {
        if (res?.data) {
          this.$toast.add({
            severity: 'success',
            summary: this.$t('toast.confirmSendVoucher'),
            life: 4000,
          });
        } else {
          this.$toast.add({
            severity: 'error',
            summary: this.$t('toast.errorSendVoucher'),
            life: 4000,
          });
        }
      });
    },
    downloadVoucher() {
      const link = document.createElement('a');
      link.href = baseURL + this.selectedBooking.voucher;
      link.target = '_blank';
      link.download = this.selectedBooking.voucher.split('/').pop();

      // Simulate a click on the element <a>
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    updateBookingValidation(status) {
      let params = {
        idBooking: this.selectedBooking.id,
        status: status,
      };
      let toastMsg = '';
      let modalMsg = '';
      if (status === 4) {
        params.requested = true;
        toastMsg = this.$t('toast.cancelSuccess');
        modalMsg = this.$t('modal.confirmCancelTxt');
      } else if (status === 3) {
        toastMsg = this.$t('toast.confirmSuccess');
        modalMsg = this.$t('modal.confirmConfirmTxt');
      }

      this.$confirm.require({
        message: modalMsg,
        header: this.$t('modal.confirmTitle'),
        acceptLabel: this.$t('modal.yes'),
        rejectLabel: this.$t('modal.no'),
        acceptClass: 'btn-pill btn-danger btn-sm px-3"',
        rejectClass: 'btn-pill btn-outline-secondary btn-sm px-3"',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.loadingUpdateStatus = true;
          this.$toast.add({
            severity: 'info',
            summary: 'Progressing',
            life: 4000,
          });
          this.updateStatusBooking(params).then((res) => {
            if (res.status == 500) {
              this.$toast.add({
                severity: 'error',
                summary: this.$t('errors.DefaultErrorToast'),
                life: 4000,
              });
            } else if (res.error && res.error == 'failed_send_email') {
              this.$toast.add({
                severity: 'error',
                summary: this.$t('errors.failed_send_email'),
                life: 4000,
              });
            } else if (res.status !== 'error') {
              this.$toast.add({
                severity: 'success',
                summary: toastMsg,
                life: 4000,
              });
            }
            this.loadingUpdateStatus = false;
          });
        },
        reject: () => {},
      });
    },
    getItemsMenu(reservation) {
      let items = [
        {
          label: 'Details',
          icon: 'pi pi-eye',
          command: () => {
            this.$router.push({ name: 'BookingDetails', params: { bookId: this.selectedBooking.id } });
          },
        },
      ];

      if (reservation.status === 2) {
        items.push({
          label: this.$t('button.confirm'),
          icon: 'pi pi-check',
          command: () => {
            this.updateBookingValidation(3);
          },
        });
      }

      if (reservation.can_cancel) {
        items.push({
          label: this.$t('button.cancel'),
          icon: 'pi pi-ban',
          command: () => {
            this.updateBookingValidation(4);
          },
        });
      }

      if (reservation.status === 3) {
        items.push({
          label: this.$t('button.downloadVoucher'),
          icon: 'pi pi-download',
          command: () => {
            this.downloadVoucher();
          },
        });
      }

      if (reservation.status === 1 || reservation.status === 3) {
        items.push({
          label: this.$t('button.sendVoucher'),
          icon: 'pi pi-send',
          command: () => {
            this.sendVoucher();
          },
        });
      }
      return items;
    },
  },
};
</script>
<style lang="scss">
.loader-box {
  height: auto !important;
}
.small-btn {
  width: 30px;
  height: 30px;
}
.p-tooltip {
  font-size: 0.75rem;
}
</style>
