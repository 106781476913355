import { createRouter, createWebHistory } from 'vue-router';
import AuthGuard from '../utils/auth.guard';

// Auth
import LoginValidate from '../pages/authentication/login.vue';
import Register from '../pages/authentication/register.vue';
import ResetPassword from '../pages/authentication/reset_pwd.vue';
import ForgetPassword from '../pages/authentication/forget_password.vue';

import Body from '../components/body';
import Dashboard from '../pages/dashboard/defaultPage.vue';
import indexHotels from '../pages/hotels/hotelsList/indexHotels.vue';
import HotelPage from '../pages/hotels/hotelpage/HotelPage.vue';

// user
import userCards from '../pages/users/list/listUsersCards.vue';
import userEdit from '../pages/users/edit/userEdit.vue';

// Orders
import MakeReservation from '../pages/orders/MakeReservation.vue';

import BookingList from '../pages/booking/BookingList.vue';
import BookingDetails from '../pages/booking/BookingDetails.vue';

import OrderSuccess from '../pages/orders/OrderSuccess.vue';
import OrderError from '../pages/orders/OrderError.vue';

const routes = [
  {
    path: '/authentication',
    name: 'auth',
    meta: {
      title: 'Connexion | TrustLuxury',
    },
    children: [
      {
        path: '/authentication/login',
        name: 'LoginValidate',
        component: LoginValidate,
      },
      {
        path: '/authentication/register',
        name: 'RegisterB2B',
        component: Register,
      },
      {
        path: '/authentication/resetPwd/:token?',
        name: 'resetPWD',
        component: ResetPassword,
      },
      {
        path: '/authentication/forgetPwd',
        name: 'recoverB2B',
        component: ForgetPassword,
      },
    ],
  },
  {
    path: '/',
    component: Body,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: '/my/profile',
        name: 'profile',
        component: userEdit,
        meta: {
          title: 'Profile | Trust Luxury',
        },
      },
      {
        path: '/users',
        name: 'usres',
        meta: {
          roles: ['admin'],
        },
        children: [
          {
            path: 'edit/:id',
            name: 'editUser',
            component: userEdit,
            meta: {
              title: 'Users Edit | Trust Luxury',
            },
          },
          {
            path: 'list',
            name: 'usersList',
            component: userCards,
            meta: {
              title: 'Users | Trust Luxury',
            },
          },
        ],
      },
      {
        path: 'hotels',
        name: 'hotels',
        children: [
          {
            path: 'list',
            name: 'hotelsList',
            component: indexHotels,
            meta: {
              title: 'Hotels | Trust Luxury',
            },
          },
          {
            path: 'details/:hotelId',
            name: 'hotelInfos',
            component: HotelPage,
            meta: {
              title: 'Hotels | Trust Luxury',
            },
          },
        ],
      },
      {
        path: 'booking',
        name: 'booking',
        children: [
          {
            path: 'list',
            name: 'BookingList',
            component: BookingList,
            meta: {
              title: 'Make Reservation | Trust Luxury',
            },
          },
          {
            path: 'details/:bookId',
            name: 'BookingDetails',
            component: BookingDetails,
            meta: {
              title: 'Booking Details | Trust Luxury',
            },
          },
        ],
      },
      {
        path: 'orders',
        name: 'orders',
        children: [
          {
            path: 'reserve/:bookHash',
            name: 'makeReservation',
            component: MakeReservation,
            meta: {
              title: 'Make Reservation | Trust Luxury',
            },
          },
          {
            path: 'success',
            name: 'BookingSuccess',
            component: OrderSuccess,
            meta: {
              title: 'Booking Successful! | Trust Luxury',
            },
          },
          {
            path: 'error',
            name: 'BookingError',
            component: OrderError,
            meta: {
              title: 'Booking Failed! | Trust Luxury',
            },
          },
        ],
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach(AuthGuard);
export default router;
