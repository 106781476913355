export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', icon: 'flag-icon-us' },
  { id: 'fr', name: 'Français', icon: 'flag-icon-fr' },
];
export const API_URL = 'https://api.trust-luxury.fr/api/v1/admin/';
export const baseURL = 'https://api.trust-luxury.fr';
export const isAuthGuardActive = true;
export const paymentTypes = [
  { type: 'deposit' },
  { type: 'wire_transfer' },
  { type: 'now', by: 'credit_card' },
  { type: 'now', by: 'paypal' },
  { type: 'now', by: 'apple_pay' },
  { type: 'facility' },
];

export const supplierData = {
  first_name_original: 'Trust',
  last_name_original: 'Luxury',
  phone: '0033605892763',
  email: 'b2b@trust-luxury.fr',
};
