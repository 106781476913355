import { bookingService } from '@/_services/booking.service';

const state = {
  bookingList: [],
  bookingLoading: false,
};
const getters = {
  bookingList: (state) => state.bookingList,
  bookingLoading: (state) => state.bookingLoading,
};
const actions = {
  createBooking({ commit }, params) {
    return bookingService.storeBooking(params).then(
      (res) => {
        return res.data;
      },
      (error) => console.error(error)
    );
  },
  getBookingList({ commit }, params) {
    commit('setBookingLoading', true);
    return bookingService.getBookingList({ params }).then(
      (res) => {
        commit('setBookingList', res.data.data);
        return res.data;
      },
      (error) => console.error(error)
    );
  },
  getBookingById({ commit }, idBooking) {
    return bookingService.getBookingById(idBooking).then(
      (res) => {
        return res.data;
      },
      (error) => console.error(error)
    );
  },
  sendBookingVoucher({ commit }, idBooking) {
    return bookingService.sendBookingVoucherById(idBooking).then(
      (res) => {
        return res.data;
      },
      (error) => console.error(error)
    );
  },
  updateStatusBookingById({ commit }, payload) {
    const params = {
      status: payload.status,
      requested: payload.requested,
    };
    return bookingService.updateStatusById(payload.idBooking, params).then(
      (res) => {
        if (res.data.status !== 'error') {
          commit('updateBookingStatus', payload);
        }
        return res.data;
      },
      (error) => {
        return error;
      }
    );
  },
};
const mutations = {
  setBookingList(state, bookingList) {
    state.bookingList = bookingList;
    state.bookingLoading = false;
  },
  setBookingLoading(state, isLoading) {
    state.bookingLoading = isLoading;
  },
  updateBookingStatus(state, bookParams) {
    let book = state.bookingList.find((book) => book.id == bookParams.idBooking);
    book.status = bookParams.status;
    if (bookParams.status == 4) {
      book.can_cancel = false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
