<template>
  <div class="page-title">
    <div class="row">
      <div class="col">
        <h3>
          {{ main }}
          <span v-if="stars" class="rating">
            <i v-for="(item, index) in stars" :key="index" class="fa fa-star"></i>
          </span>
        </h3>
      </div>
      <div class="col d-lg-block d-none">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ path: '/' }">
              <svg class="stroke-icon">
                <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
              </svg>
            </router-link>
          </li>
          <li class="breadcrumb-item" v-if="main">{{ main }}</li>
          <li class="breadcrumb-item active" v-if="title">{{ title }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      default: 'home',
    },
    main: {
      default: '',
    },
    stars: {
      default: 0,
    },
  },
};
</script>
