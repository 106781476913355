<template>
  <div class="form-group search-box__input-autocomplete">
    <span class="p-float-label">
      <AutoComplete
        inputId="searchDestination"
        v-model="selectedCity"
        :completeOnFocus="selectedCity ? true : false"
        :suggestions="filteredCities"
        @item-select="$emit('change-city', selectedCity)"
        @complete="search"
        optionLabel="label"
        optionGroupLabel="name"
        optionGroupChildren="items"
        :placeholder="$t('wording.searchPlaceholder')">
        <template #optiongroup="slotProps">
          <div class="flex align-items-center country-item">
            <div>{{ slotProps.item.label }}</div>
          </div>
        </template>
      </AutoComplete>
      <label for="searchDestination">{{ $t('wording.destination') }}</label>
    </span>
  </div>
</template>

<script>
import 'primevue/resources/themes/aura-light-green/theme.css';
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      selectedCity: '',
      filteredCities: '',
    };
  },
  created() {
    const query = this.$route.query;
    if (query.search_value) {
      this.selectedCity = JSON.parse(query.search_value);
    }
  },
  methods: {
    ...mapActions(['searchAutocomplet']),
    search(event) {
      if (event.query) {
        let params = {
          query: event.query,
          language: this.$i18n.locale,
        };
        setTimeout(() => {
          this.searchAutocomplet(params).then((res) => {
            this.filteredCities = [];
            let formatRegions = res.data.regions.map((region) => {
              return { ...region, label: region.name + ', ' + region.country_code };
            });
            let formatHotels = res.data.hotels.map((hotel) => {
              return { ...hotel, label: hotel.name };
            });
            if (formatRegions.length > 0) {
              this.filteredCities.unshift({
                label: 'Régions',
                code: 'regions',
                items: formatRegions,
              });
            }

            if (formatHotels.length > 0) {
              this.filteredCities.unshift({
                label: 'Hôtels',
                code: 'hotels',
                items: formatHotels,
              });
            }
          });
        }, 200);
      }
    },
  },
};
</script>
